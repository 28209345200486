import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  FaInfoCircle,
  FaMicrophone,
  FaPaperPlane,
  FaStop,
} from "react-icons/fa";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import config from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import { useSimulationMode } from "../../contexts/SimulationModeContext";
import { Switch } from "../ui/switch";
import { Toaster, toaster } from "../ui/toaster";
import { Tooltip } from "../ui/tooltip";

import { SimulationInterfaceHeader } from "./SimulationInterfaceHeader";
import { SimulationInterfaceSideDrawer } from "./SImulationInterfaceSideDrawer";
import { WelcomeOverlay } from "./WelcomeOverlay";

const { API_BASE_URL } = config;

// ==========================
// Helper Components
// ==========================

const PatientSection = ({ patientMessage, isPatientSpeaking, imageUrl }) => {
  const borderColor = "green.400";
  const messageBg = "white";

  return (
    <VStack spacing={6} align="center">
      <Box position="relative">
        <Box
          borderRadius="full"
          borderWidth={isPatientSpeaking ? "3px" : "0px"}
          borderColor={borderColor}
          p={1}
        >
          <Image
            src={imageUrl || "/images/default_person.jpg"}
            borderRadius="full"
            boxSize="400px"
            objectFit="cover"
            crossOrigin="anonymous"
            onError={(e) => {
              console.error("Image load error:", e);
              e.target.src = "/images/default_person.jpg";
            }}
            onLoad={(e) => {
              console.log("Image loaded successfully:", e.target.src);
            }}
            alt="Patient"
          />
        </Box>
      </Box>
      {patientMessage && (
        <Box
          bg={messageBg}
          p={4}
          borderRadius="md"
          w="100%"
          maxW="600px"
          boxShadow="sm"
        >
          <Text>
            <strong>Patient:</strong> {patientMessage}
          </Text>
        </Box>
      )}
    </VStack>
  );
};

PatientSection.propTypes = {
  patientMessage: PropTypes.string,
  isPatientSpeaking: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string,
};

const UserInputSection = ({
  transcribedText,
  isRecording,
  onStartRecording,
  onStopRecording,
  showTextInput,
  onTextSubmit,
  textInput,
  onTextInputChange,
  isDisabled,
  setShowTextInput,
  currentMode,
}) => {
  return (
    <VStack spacing={4} w="full" maxW="600px" mx="auto">
      {transcribedText && (
        <Box bg="blue.50" p={4} borderRadius="md" w="full" boxShadow="sm">
          <Text>
            <strong>You:</strong> {transcribedText}
          </Text>
        </Box>
      )}

      {currentMode.features.showTextInput && (
        <HStack width="full" spacing={4}>
          <Switch
            colorPalette="teal"
            isChecked={showTextInput}
            onChange={() => setShowTextInput(!showTextInput)}
          />
          <Text>{showTextInput ? "Hide" : "Show"} Text Input</Text>
        </HStack>
      )}

      {showTextInput && (
        <HStack as="form" onSubmit={onTextSubmit} w="full">
          <Input
            value={textInput}
            onChange={onTextInputChange}
            placeholder="Type your message... (say 'goodbye' to end)"
            isDisabled={isDisabled}
          />
          <IconButton
            onClick={onTextSubmit}
            isDisabled={isDisabled || !textInput}
            colorPalette="teal"
            aria-label="Send message"
          >
            <FaPaperPlane />
          </IconButton>
        </HStack>
      )}

      <Box position="relative">
        <Tooltip
          content="To end the simulation, simply say 'goodbye' to the patient"
          placement="top"
          hasArrow
        >
          <Box position="absolute" top="-8" right="-8" cursor="pointer">
            <FaInfoCircle color="gray" />
          </Box>
        </Tooltip>

        <IconButton
          rounded="full"
          colorPalette={isRecording ? "red" : "teal"}
          onClick={isRecording ? onStopRecording : onStartRecording}
          isDisabled={isDisabled}
          size="lg"
          width="80px"
          height="80px"
          fontSize="2xl"
          boxShadow="lg"
          _hover={{ transform: "scale(1.05)", boxShadow: "xl" }}
          transition="all 0.2s"
          aria-label={isRecording ? "Stop recording" : "Start recording"}
        >
          {isRecording ? <FaStop /> : <FaMicrophone />}
        </IconButton>
      </Box>
    </VStack>
  );
};

UserInputSection.propTypes = {
  transcribedText: PropTypes.string,
  isRecording: PropTypes.bool.isRequired,
  onStartRecording: PropTypes.func.isRequired,
  onStopRecording: PropTypes.func.isRequired,
  showTextInput: PropTypes.bool.isRequired,
  onTextSubmit: PropTypes.func.isRequired,
  textInput: PropTypes.string.isRequired,
  onTextInputChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setShowTextInput: PropTypes.func.isRequired,
  currentMode: PropTypes.object.isRequired,
};

// ==========================
// Main Simulation Interface
// ==========================

const SimulationInterface = () => {
  // ----------------------------------
  // State Declarations
  // ----------------------------------

  // Add ui state for coming from backend
  const [initialMessage, setInitialMessage] = useState("");
  const [patientImageUrl, setPatientImageUrl] = useState(null);
  const [sidebarContent, setSidebarContent] = useState({
    resource: "",
    prescription: "",
    progress: {},
  });

  // Core simulation state
  const [sessionId, setSessionId] = useState(() => uuidv4());
  const [messages, setMessages] = useState([]);
  const [feedback, setFeedback] = useState(null);

  // UI state
  const [input, setInput] = useState("");
  const [showTextInput, setShowTextInput] = useState(false);
  const [isOverlayOpen, setIsOverlayOpen] = useState(true);

  // Welcome overlay timer
  const [hasStartedSimulation, setHasStartedSimulation] = useState(false);

  // Audio state
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  // Loading and simulation status
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isSimulationEnded, setIsSimulationEnded] = useState(false);
  const [isEndingSimulation, setIsEndingSimulation] = useState(false);

  // New state for handling the last message only
  const [currentPatientMessage, setCurrentPatientMessage] = useState("");
  const [transcribedText, setTranscribedText] = useState("");

  // ----------------------------------
  // Hooks and Refs
  // ----------------------------------
  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  // Custom hooks
  const navigate = useNavigate();
  const { user } = useAuth();
  const { scenarioId } = useParams();
  const { currentMode, timeRemaining, setTimeRemaining } = useSimulationMode();

  // ----------------------------------
  // Effects
  // ----------------------------------
  // On mount or scenario change => setup
  useEffect(() => {
    if (scenarioId && sessionId) {
      setupSimulation();
    } else {
      handleSetupError("Missing session or scenario information");
    }
    // cleanup logic on unmount
    return () => {
      stopRecording();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId, sessionId]);

  // Audio element listeners
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handlePlay = () => setIsAudioPlaying(true);
      const handleEnded = () => {
        setIsAudioPlaying(false);
        setIsWaitingForResponse(false);
      };
      const handlePause = () => setIsAudioPlaying(false);

      audio.addEventListener("play", handlePlay);
      audio.addEventListener("ended", handleEnded);
      audio.addEventListener("pause", handlePause);

      return () => {
        audio.removeEventListener("play", handlePlay);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  // Cleanup function for microphone
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach((track) => track.stop());
        mediaRecorderRef.current = null;
        setIsRecording(false);
      }
    };
  }, []);

  // Exam mode timer countdown
  useEffect(() => {
    let timer;
    if (hasStartedSimulation && currentMode.id === "exam" && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNormalEnding();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [hasStartedSimulation, currentMode.id, timeRemaining, setTimeRemaining]);

  // ----------------------------------
  // Setup Functions
  // ----------------------------------
  const setupSimulation = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/setup_simulation`, {
          scenarioId,
          sessionId,
          userId: user?.sub,
          mode: currentMode.id,
      });

      const { initialMessage, imageUrl, sidebar } = response.data;
      setInitialMessage(initialMessage || "");
      setPatientImageUrl(imageUrl || "");
      setSidebarContent(
        sidebar || {
          resource: "",
          prescription: "",
          progress: {},
        }
      );

      // If exam mode, set the time
      if (currentMode.id === "exam") {
        setTimeRemaining(currentMode.features.examDuration);
      }
    } catch (error) {
      handleError("Failed to set up simulation", error);
      navigate("/scenarios");
    }
  };

  const handleSetupError = (message) => {
    console.error(message);
    showToast("error", message);
    navigate("/scenarios");
  };

  // ----------------------------------
  // Starting Simulation
  // ----------------------------------
  const handleStartButtonClick = async () => {
    setIsOverlayOpen(false);
    setHasStartedSimulation(true);

    if (currentMode.id === "exam") {
      setTimeRemaining(currentMode.features.examDuration);
    }

    try {
      // Start simulation => get first patient message
      const res = await axios.post(`${API_BASE_URL}/api/start_simulation`, {
        sessionId,
        userId: user?.sub,
      });
      const first = res.data.firstMessage;
      if (first && first.content) {
        setCurrentPatientMessage(first.content);
        // Pre-load audio
        if (first.audioS3Url) {
          playAudioFromS3(first.audioS3Url);
        }
      }
    } catch (error) {
      handleError("Failed to start simulation", error);
      navigate("/scenarios");
    }
  };

  // ----------------------------------
  // Sending Text
  // ----------------------------------
  const handleTextSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isWaitingForResponse || isSimulationEnded) return;

      const message = input.trim();
      setTranscribedText(message);
    setMessages((prev) => [...prev, { sender: "You", content: message }]);
      setInput("");
    await sendUserMessage(message);
  };

  const sendUserMessage = async (message, timestamp = null) => {
    setIsWaitingForResponse(true);
    try {
      const res = await axios.post(`${API_BASE_URL}/api/send_message`, {
        sessionId,
        message,
        timestamp,
      });

      if (res.data.terminated) {
        // This means user triggered 'goodbye'
        handleNormalEnding();
        return;
      }

      if (res.data.assistant_reply) {
        const { content, audioS3Url } = res.data.assistant_reply;
        setCurrentPatientMessage(content);
        // Attempt to play AI audio if available
        if (audioS3Url) {
          playAudioFromS3(audioS3Url);
        }
      }
    } catch (error) {
      handleError("Failed to send message", error);
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  // ----------------------------------
  // Recording Audio
  // ----------------------------------
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
        await sendAudio(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error(error);
      showToast("error", "Failed to start recording. Please check microphone permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
      setIsRecording(false);
    }
  };

  const sendAudio = async (blob) => {
    setIsWaitingForResponse(true);
    try {
      // 1) Transcribe the audio
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");
    formData.append("sessionId", sessionId);
    formData.append("userId", user?.sub);

      const transcribeRes = await axios.post(
        `${API_BASE_URL}/api/transcribe_audio`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const { text, timestamp } = transcribeRes.data;
      if (!text || !text.trim()) {
        showToast("warning", "No speech detected. Please try again.");
        setIsWaitingForResponse(false);
        return;
      }

      setTranscribedText(text);

      // 2) Send the transcribed text to the simulation
      await sendUserMessage(text, timestamp);
    } catch (error) {
      handleError("Failed to process audio", error);
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  // ----------------------------------
  // Playing AI Audio
  // ----------------------------------
  const playAudioFromS3 = (s3Url) => {
    // Our backend typically has a path s3:// or a file name. 
    // If needed, you might create an endpoint to fetch the streaming URL
    // or store it in "temp_audio" and retrieve with /api/audio. Adjust accordingly.

    // If you store the file in local temp, you'll have a path like:
    //   GET /api/audio/filename
    // For S3, you can either get a signed URL or direct link. This depends on your config.

    // For demonstration, let's assume your VoiceService also stored a local copy
    // and returned a /api/audio/ file name we can retrieve directly:
    const fileName = s3Url.split("/").pop(); // naive
    const audioUrl = `${API_BASE_URL}/api/audio/${fileName}`;

    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current.play().catch((e) => console.error("Error playing audio:", e));
    }
  };

  // ----------------------------------
  // Ending / Navigating
  // ----------------------------------
  const handleNormalEnding = async () => {
    setIsEndingSimulation(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/end_simulation_normal`, {
          sessionId,
          userId: user?.sub,
          scenarioId,
          mode: currentMode.id,
      });
      setFeedback(response.data.feedback);
      setIsSimulationEnded(true);
    } catch (error) {
      handleError("Failed to end simulation", error);
    } finally {
      setIsEndingSimulation(false);
    }
  };

  const handleGoToResult = () => {
    if (isSimulationEnded && feedback) {
      // Store feedback in sessionStorage
      sessionStorage.setItem(
        `simulation_feedback_${sessionId}`,
        JSON.stringify(feedback)
      );
      
      // Navigate without passing feedback in state
      // Not passing 'from' state since this is direct from simulation
      navigate(`/simulation-result/${sessionId}`);
    }
  };

  const handleRestart = () => {
    setMessages([]);
    setInput("");
    setIsSimulationEnded(false);
    setFeedback(null);
    setInitialMessage("");
    setSessionId(uuidv4());
    setIsOverlayOpen(true);
    setHasStartedSimulation(false);
    if (currentMode.id === "exam") {
      setTimeRemaining(null);
    }
  };

  // ----------------------------------
  // Helpers
  // ----------------------------------
  const handleError = (message, error) => {
    console.error(`${message}:`, error);
    showToast(
      "error",
      error.response?.data?.detail || `${message}. Please try again.`
    );
  };

  const showToast = (type, description) => {
    toaster.create({
      title: type === "error" ? "Error" : "Warning",
      description,
      type,
      duration: 5000,
      isClosable: true,
    });
  };

  // ----------------------------------
  // Render
  // ----------------------------------
  return (
    <>
      <Flex height={"100%"}>
        <Toaster />
        <Flex flex={1} overflow="hidden">
          <Box flex={1} p={6} overflowY="auto">
            <SimulationInterfaceHeader handleRestart={handleRestart} />
            <VStack spacing={8} align="stretch">
              <PatientSection
                patientMessage={currentPatientMessage}
                isPatientSpeaking={isAudioPlaying}
                imageUrl={patientImageUrl}
              />

              <UserInputSection
                transcribedText={transcribedText}
                isRecording={isRecording}
                onStartRecording={startRecording}
                onStopRecording={stopRecording}
                showTextInput={showTextInput}
                onTextSubmit={handleTextSubmit}
                textInput={input}
                onTextInputChange={(e) => setInput(e.target.value)}
                isDisabled={
                  isSimulationEnded ||
                  (!isRecording && (isWaitingForResponse || isAudioPlaying)) ||
                  (currentMode.id === "exam" && timeRemaining <= 0)
                }
                setShowTextInput={setShowTextInput}
                currentMode={currentMode}
              />
            </VStack>
          </Box>
        </Flex>
        <SimulationInterfaceSideDrawer sidebarContent={sidebarContent} />

        <WelcomeOverlay
          isOpen={isOverlayOpen}
          initialMessage={initialMessage}
          handleSimulationStart={handleStartButtonClick}
          hasStartedSimulation={hasStartedSimulation}
        />

        {/* Status Indicators */}
        {isEndingSimulation && (
          <Flex
            position="fixed"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
            bg="white"
            p={4}
            borderRadius="md"
            boxShadow="md"
            zIndex={1000}
          >
            <Spinner colorPalette="teal.500" />
            <Text ml={2}>Ending simulation...</Text>
          </Flex>
        )}

        {/* Result Navigation */}
        {isSimulationEnded && (
          <Flex
            position="fixed"
            bottom="20px"
            left="50%"
            transform="translateX(-50%)"
            zIndex={1000}
          >
            <Button
              colorPalette="teal"
              onClick={handleGoToResult}
              isDisabled={!feedback}
              boxShadow="md"
              _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
              transition="all 0.2s"
            >
              Go to Result
            </Button>
          </Flex>
        )}

        {/* Hidden audio player */}
        <audio ref={audioRef} style={{ display: "none" }} />
      </Flex>
    </>
  );
};

export default SimulationInterface;
