import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CognitoUser } from "amazon-cognito-identity-js";
import { userPool } from "../contexts/AuthContext";
import config from "../config";
import { Field } from "../components/ui/field";
import { Button } from "../components/ui/button";

export default function ConfirmSignUp() {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [userSub, setUserSub] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
      setUserSub(location.state.userSub);
      setFirstName(location.state.firstName);
      setLastName(location.state.lastName);
      setDisplayName(location.state.displayName);
    } else {
      setError("Email not provided. Please go back to the sign-up page.");
    }
  }, [location.state]);

  const createUserProfile = async () => {
    try {
      const response = await axios.post(
        `${config.API_BASE_URL}/api/create_user_profile`,
        {
          id: userSub,
          email,
          display_name: displayName,
          first_name: firstName,
          last_name: lastName,
        }
      );
      console.log("User profile created:", response.data);
      return true;
    } catch (error) {
      console.error("Error creating user profile:", error);
      setError(
        "We couldn't create your profile. Please try again or contact support."
      );
      return false;
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    if (!email) {
      setError("Email is required. Please go back to the sign-up page.");
      setIsSubmitting(false);
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.confirmRegistration(code, true, async (err) => {
      setIsSubmitting(false);

      if (err) {
        setError(err.message || "Error during confirmation");
        return;
      }

        setSuccess("Email confirmed successfully!");

      // Attempt to create user profile in your backend
        const profileCreated = await createUserProfile();
        if (profileCreated) {
        // After 2s, go to your main app page (e.g. /dashboard)
          setTimeout(() => navigate("/dashboard"), 2000);
        } else {
        // If user profile creation fails, fallback to /login
          navigate("/login");
        }
    });
  };

  const handleResendCode = () => {
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    if (!email) {
      setError("Email is required");
      setIsSubmitting(false);
      return;
    }

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err) => {
      setIsSubmitting(false);
      if (err) {
        setError(err.message || "Error during resending code");
      } else {
        setSuccess("Verification code resent successfully!");
      }
    });
  };

  return (
    <Container maxW="sm" mt={8}>
      <Box mb={4}>
        <Text fontSize="2xl" mb={2}>
          Confirm Sign Up
        </Text>
      </Box>

        {error && (
        <Box p={4} mb={4} bg="red.100" color="red.700" borderRadius="md">
            {error}
        </Box>
        )}
      
        {success && (
        <Box p={4} mb={4} bg="green.100" color="green.700" borderRadius="md">
            {success}
        </Box>
        )}

        <form onSubmit={handleConfirmSignUp}>
        <VStack spacing={4}>
          <Field label="Verification Code">
            <Input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </Field>

          <Button
            type="submit"
            colorPalette="blue"
            width="full"
            loading={isSubmitting}
          >
            Confirm
          </Button>

        <Button
          onClick={handleResendCode}
          variant="link"
          width="full"
            colorPalette="blue"
            disabled={isSubmitting}
        >
          Resend Code
        </Button>
        </VStack>
      </form>
    </Container>
  );
}
