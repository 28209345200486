import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Box, Spinner, Alert } from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading, sessionError } = useAuth();
  const location = useLocation();

  console.log("PrivateRoute state:", {
    isAuthenticated,
    isLoading,
    sessionError,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" colorPalette="blue.500" borderWidth="4px" />
      </Box>
    );
  }

  if (sessionError) {
    return (
      <Box p={8}>
        <Alert
          status="error"
          title={`Authentication Error: ${sessionError}`}
        ></Alert>
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
