import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Flex, Icon, Text } from "@chakra-ui/react";
import { ProgressBar, ProgressRoot } from "../ui/progress";

export const TrainingCategoryCard = ({
  category,
  onSelect,
  isCardSelected,
}) => {
  const { icon, name, description, completedProblems, totalProblems } =
    category;

  return (
    <Card.Root
      cursor="pointer"
      onClick={onSelect}
      bg={isCardSelected ? "blue.50" : "white"}
      borderColor={isCardSelected ? "blue.500" : "gray.200"}
      borderWidth={2}
      transition="all 0.3s"
      _hover={{ transform: "translateY(-2px)", shadow: "md" }}
    >
      <Card.Body justifyContent="space-between">
        <Flex align="top" justify="space-between" mb={2}>
          <div>
            <Card.Title>{name}</Card.Title>
            <Text fontSize="sm" color="gray.600" mb={3}>
              {description}
            </Text>
          </div>
          <Box
            minW="50px"
            h="50px"
            backgroundColor="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="5px"
            ml={2}
          >
            <Icon size="md" color="gray.600">
              {icon}
            </Icon>
          </Box>
        </Flex>
        <ProgressRoot
          size="sm"
          mb={2}
          colorPalette="blue"
          value={(completedProblems / totalProblems) * 100}
        >
          <ProgressBar />
        </ProgressRoot>
        <Text fontSize="sm" color="gray.600">
          {completedProblems} / {totalProblems} completed
        </Text>
      </Card.Body>
    </Card.Root>
  );
};

TrainingCategoryCard.propTypes = {
  category: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    completedProblems: PropTypes.number.isRequired,
    totalProblems: PropTypes.number.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  isCardSelected: PropTypes.bool.isRequired,
};
