import React, { createContext, useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import awsmobile from '../aws-exports';
import config from '../config';

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const SESSION_CHECK_INTERVAL = 5 * 60 * 1000; // Check session validity every 5 minutes

const poolData = {
  UserPoolId: awsmobile.aws_user_pools_id,
  ClientId: awsmobile.aws_user_pools_web_client_id,
  Region: awsmobile.aws_cognito_region
};

export const userPool = new CognitoUserPool(poolData);

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [sessionError, setSessionError] = useState(null);
  
  // Use refs to store the latest values for use in intervals/timeouts
  const userRef = useRef(user);
  const lastFetchTimeRef = useRef(lastFetchTime);
  
  // Update refs when state changes
  useEffect(() => {
    userRef.current = user;
    lastFetchTimeRef.current = lastFetchTime;
  }, [user, lastFetchTime]);

  // Memoized function to get cached user data
  const getCachedUserData = useCallback(() => {
    if (!lastFetchTimeRef.current || !userRef.current) return null;
    
    const timeSinceLastFetch = new Date().getTime() - lastFetchTimeRef.current;
    if (timeSinceLastFetch < CACHE_DURATION) {
      return userRef.current;
    }
    return null;
  }, []);

  const fetchUserData = useCallback(async (cognitoUser, retryCount = 0) => {
    if (lastFetchTime && (new Date().getTime() - lastFetchTime) < CACHE_DURATION) {
      return;
    }

    const maxRetries = 3;
    const cachedData = getCachedUserData();
    
    if (cachedData) {
      console.log('Using cached user data');
      return cachedData;
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          console.error('Error fetching Cognito attributes:', err);
          reject(err);
          return;
        }

        const userData = {};
        attributes.forEach(attribute => {
          userData[attribute.Name] = attribute.Value;
        });

        // Fetch additional user data from backend with retry logic
        const fetchBackendData = async () => {
          try {
            const response = await fetch(`${config.API_BASE_URL}/api/user/${userData.sub}`);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const backendData = await response.json();
            const combinedUserData = { ...userData, ...backendData };
            
            setUser(combinedUserData);
            setLastFetchTime(new Date().getTime());
            resolve(combinedUserData);
          } catch (error) {
            console.error('Error fetching backend data:', error);
            if (retryCount < maxRetries) {
              console.log(`Retrying backend fetch (${retryCount + 1}/${maxRetries})`);
              setTimeout(() => {
                fetchBackendData();
              }, Math.pow(2, retryCount) * 1000); // Exponential backoff
            } else {
              console.warn('Using Cognito-only data after max retries');
              setUser(userData);
              setLastFetchTime(new Date().getTime());
              resolve(userData);
            }
          }
        };

        fetchBackendData();
      });
    });
  }, [getCachedUserData, lastFetchTime]);

  const checkAuthStatus = useCallback(async (force = false) => {
    console.log('Starting checkAuthStatus', { force, isLoading });
    
    setIsLoading(true);
    setSessionError(null);
    
    try {
      const cognitoUser = userPool.getCurrentUser();
      console.log('Current Cognito user:', cognitoUser ? 'exists' : 'null');
      
      if (!cognitoUser) {
        console.log('No Cognito user - setting unauthenticated');
        setIsAuthenticated(false);
        setUser(null);
        setLastFetchTime(null);
        setIsLoading(false); 
        return;
      }
  
      return new Promise((resolve) => {
        cognitoUser.getSession(async (err, session) => {
        try {
          if (err) {
            console.error('Session error:', err);
            setSessionError(err.message);
            setIsAuthenticated(false);
            setUser(null);
            resolve(false);
            return;
          }
  
          console.log('Session valid:', session?.isValid());
          if (session?.isValid()) {
            setIsAuthenticated(true);
            await fetchUserData(cognitoUser);
            resolve(true);
          } else {
            setIsAuthenticated(false);
            setUser(null);
            resolve(false);
          }
        } finally {
            setIsLoading(false);
        }
        });
      });
    } catch (error) {
      console.error('Auth status check error:', error);
      setSessionError(error.message);
      setIsAuthenticated(false);
      setUser(null);
      setIsLoading(false);
    } finally {
      console.log('Finishing checkAuthStatus');
      setIsLoading(false);
    }
  }, [fetchUserData]);

  // Run once at startup
  useEffect(() => {
    checkAuthStatus(false);

    // Set up periodic session checks only if authenticated
    const sessionCheckInterval = setInterval(() => {
      if (userRef.current) {
        checkAuthStatus(true);
      }
    }, SESSION_CHECK_INTERVAL);

    return () => {
      clearInterval(sessionCheckInterval);
    };
  }, [checkAuthStatus]);

  const signOut = useCallback(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setIsAuthenticated(false);
      setUser(null);
      setLastFetchTime(null);
      setSessionError(null);
    }
  }, []);

  const refreshUserData = useCallback(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      return fetchUserData(cognitoUser, 0);
    }
    return Promise.reject(new Error('No authenticated user'));
  }, [fetchUserData]);

  const contextValue = useMemo(() => ({
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    signOut,
    checkAuthStatus,
    user,
    refreshUserData,
    sessionError
  }), [isAuthenticated, isLoading, user, sessionError, checkAuthStatus, refreshUserData]);
  
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
