import {
  Box,
  Container,
  HStack,
  Icon,
  Separator,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FiAtSign } from "react-icons/fi";
import { IoInformationCircle } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { useAuth } from "../contexts/AuthContext";

const Profile = () => {
  const { user, isLoading } = useAuth();

  // If still loading user data from AuthContext, show a spinner
  if (isLoading) {
    return (
      <Box
        bgGradient="linear(to-r, blue.50, white)"
        minH="100vh"
        py={10}
        px={4}
      >
        <Container maxW="container.sm">
          <Box
            bg="white"
            borderRadius="lg"
            boxShadow="md"
            p={6}
            textAlign="center"
            overflow="hidden"
          >
            <Spinner size="xl" colorPalette="blue.500" borderWidth="4px" />
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box bgGradient="linear(to-r, blue.50, white)" minH="100vh" py={10} px={4}>
      <Container maxW="container.sm">
        <Box
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          p={6}
          textAlign="center"
          overflow="hidden"
        >
          <Text fontSize="3xl" fontWeight="bold" mb={4} color="blue.500">
            Profile
          </Text>
          <Separator mb={6} />
          {user ? (
            <VStack spacing={4} align="start">
              <HStack>
                <Icon color="blue.400">
                  <IoInformationCircle />
                </Icon>
                <Text fontSize="lg" fontWeight="medium">
                  Display Name: <strong>{user.display_name || "N/A"}</strong>
                </Text>
              </HStack>
              <HStack>
                <Icon color="blue.400">
                  <IoInformationCircle />
                </Icon>
                <Text fontSize="lg" fontWeight="medium">
                  First Name: <strong>{user.first_name || "N/A"}</strong>
                </Text>
              </HStack>
              <HStack>
                <Icon color="blue.400">
                  <IoInformationCircle />
                </Icon>
                <Text fontSize="lg" fontWeight="medium">
                  Last Name: <strong>{user.last_name || "N/A"}</strong>
                </Text>
              </HStack>
              <HStack>
                <Icon color="blue.400">
                  <MdEmail />
                </Icon>
                <Text fontSize="lg" fontWeight="medium">
                  Email: <strong>{user.email || "N/A"}</strong>
                </Text>
              </HStack>
              <HStack>
                <Icon color="blue.400">
                  <FiAtSign />
                </Icon>
                <Text fontSize="lg" fontWeight="medium">
                  Subscription Plan: <strong>{user.user_tier || "Free"}</strong>
                </Text>
              </HStack>
            </VStack>
          ) : (
            <Text fontSize="lg" color="gray.500">
              No user data available.
            </Text>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
