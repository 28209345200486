import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsArrowRepeat } from "react-icons/bs";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import config from "../../config";
import { Alert } from "../ui/alert";
import { Button } from "../ui/button";
import { Toaster, toaster } from "../ui/toaster";

import { DetailedFeedbackCard } from "./DetailedFeedbackCard";
import { PerformanceAndSummaryCard } from "./PerformanceAndSummaryCard";
import { RecommendationsCard } from "./RecommendationsCard";
import { ConversationPanel } from "./ConversationPanel";

const { API_BASE_URL } = config;

const SimulationResult = () => {
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId } = useParams();
  const [retryCount, setRetryCount] = useState(0);

  // Check if we came from study history
  const fromStudyHistory = location.state?.from === 'study-history';

  const handleBack = () => {
    if (fromStudyHistory) {
      navigate('/study-history');
    } else {
      navigate('/dashboard');
    }
  };

  const fetchFeedback = useCallback(async () => {
    if (!sessionId) {
      setError("Session ID not found. Unable to fetch feedback.");
      setLoading(false);
      return;
    }

    // First, try to get feedback from session storage
    const storedFeedback = sessionStorage.getItem(`simulation_feedback_${sessionId}`);
    if (storedFeedback) {
      try {
        const parsedFeedback = JSON.parse(storedFeedback);
        setFeedback(parsedFeedback);
        setLoading(false);
        // Clean up the session storage
        sessionStorage.removeItem(`simulation_feedback_${sessionId}`);
        return;
      } catch (err) {
        console.error("Error parsing stored feedback:", err);
        // If there's an error parsing the stored feedback, continue to fetch from API
      }
    }

    // If no stored feedback or error parsing it, fetch from API
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/coach_feedback/${sessionId}`
      );
      if (
        response.data.feedback &&
        Object.keys(response.data.feedback).length > 0
      ) {
        setFeedback(response.data.feedback);
      } else {
        setError("Received empty feedback from the server.");
      }
    } catch (err) {
      console.error("Error fetching feedback:", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(
          `Server error: ${err.response.status} - ${
            err.response.data.detail || err.response.data
          }`
        );
      } else if (err.request) {
        // The request was made but no response was received
        setError(
          "No response received from the server. Please check your connection."
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        setError(`Error setting up the request: ${err.message}`);
      }
      toaster.create({
        title: "Error",
        description: `Failed to fetch feedback. ${err.message}`,
        type: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [sessionId]);

  useEffect(() => {
    fetchFeedback();
  }, [fetchFeedback, retryCount]);

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    setRetryCount((prevCount) => prevCount + 1);
  };

  if (error) {
    return (
      <Box bg="red.100" p={4} borderRadius="md" mb={6}>
        <Text color="red.600">{error}</Text>
        <Button
          leftIcon={<Icon><BsArrowRepeat /></Icon>}
          onClick={handleRetry}
          mt={2}
        >
          Retry
        </Button>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <>
      <ConversationPanel
        conversationLog={feedback?.conversation_log}
        isOpen={isPanelOpen}
        onToggle={() => setIsPanelOpen(!isPanelOpen)}
      />
      <Box
        marginLeft={isPanelOpen ? "320px" : "0"}
        transition="margin 0.3s ease-in-out"
      >
    <Container maxW="container.xl" py={8}>
      <Toaster />
      <Box px={6}>
        <Flex align="center" justify="space-between" mb={4}>
          <Heading size="4xl" mb={2} color="blue.500">
            Simulation Result
          </Heading>
          <Button
            colorPalette="blue"
            size="lg"
                onClick={handleBack}
            variant="outline"
          >
                {fromStudyHistory ? 'Go back to study history' : 'Go back to dashboard'}
          </Button>
        </Flex>
        {feedback ? (
          feedback.is_generic ? (
            <Alert
              status="warning"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              title="Feedback Unavailable"
            >
              {feedback.message}
            </Alert>
          ) : (
            <VStack spacing={6} align="stretch">
              <PerformanceAndSummaryCard feedback={feedback} />
              <RecommendationsCard recommendations={feedback.recommendations} />
              <DetailedFeedbackCard sections={feedback.sections} />
            </VStack>
          )
        ) : (
          <Text color="gray.500">
            No feedback available at this time. Please try again later.
          </Text>
        )}
      </Box>
    </Container>
      </Box>
    </>
  );
};

export default SimulationResult;
