import {
  Badge,
  Box,
  Group,
  HStack,
  Icon,
  Input,
  Stack,
  Table,
  Text,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { CircleCheckBig, CircleIcon, CircleDotIcon } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "../ui/select";
import { Tooltip } from "../ui/tooltip";

const getStatusIcon = (status) => {

  switch (status) {
    case "Passed":
      return (
        <Tooltip content="Passed" showArrow>
          <Flex align="center" p={1}>
            <CircleCheckBig size={20} color="#22C55E" />
          </Flex>
        </Tooltip>
      );
    case "Not Started":
      return (
        <Tooltip content="Not attempted" showArrow>
          <Flex align="center" p={1}>
            <CircleIcon size={18} color="#6B7280" />
          </Flex>
        </Tooltip>
      );
    case "In Progress":
      return (
        <Tooltip content="Attempted but not passed" showArrow>
          <Flex align="center" p={1}>
            <CircleDotIcon size={18} color="#EAB308" />
          </Flex>
        </Tooltip>
      );
    default:
      return (
        <Flex align="center" p={1}>
          <CircleIcon size={18} color="#6B7280" />
        </Flex>
      );
  }
};

const getDifficultyColor = (difficulty) => {
  switch (difficulty?.toLowerCase()) {
    case 'easy':
      return 'green.500';
    case 'medium':
      return 'yellow.500';
    case 'hard':
      return 'red.500';
    default:
      return 'gray.500';
  }
};

export default function ScenarioList({ scenarios }) {
  const navigate = useNavigate();

  // Filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [difficultyFilter, setDifficultyFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const filteredScenarios = scenarios?.filter((scenario) => {
    const matchesSearch = scenario.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = !categoryFilter || scenario.category === categoryFilter;
    const matchesDifficulty = !difficultyFilter || scenario.difficulty === difficultyFilter;
    const matchesStatus = !statusFilter || scenario.status === statusFilter;
    return matchesSearch && matchesCategory && matchesDifficulty && matchesStatus;
  }) || [];

  const uniqueCategories = [...new Set(scenarios?.map((s) => s.category))].filter(Boolean);

  return (
    <Box>
      {/* Filters */}
      <HStack spacing={4} mb={6} w="full">
        <SelectRoot size="sm" width="200px">
          <SelectTrigger>
            <SelectValueText placeholder="Category" />
          </SelectTrigger>
          <SelectContent>
            {uniqueCategories.map((category) => (
              <SelectItem key={category} item={category}>
                {category}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>

        <SelectRoot size="sm" width="200px">
          <SelectTrigger>
            <SelectValueText placeholder="Difficulty" />
          </SelectTrigger>
          <SelectContent>
            {["Easy", "Medium", "Hard"].map((difficulty) => (
              <SelectItem key={difficulty} item={difficulty}>
                {difficulty}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>

        <SelectRoot size="sm" width="200px">
          <SelectTrigger>
            <SelectValueText placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            {["Not Started", "In Progress", "Passed"].map((status) => (
              <SelectItem key={status} item={status}>
                {status}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>

        <Stack maxW="300px">
          <Group>
            <Input
              pointerEvents="none"
              startElement={
                <Icon color="gray.300">
                  <BsSearch />
                </Icon>
              }
            />
            <Input
              placeholder="Search scenarios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Group>
        </Stack>
      </HStack>

      {/* Scenarios Table */}
      <Table.Root variant="simple" size="md">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeader width="80px" textAlign="center">Status</Table.ColumnHeader>
            <Table.ColumnHeader>Title</Table.ColumnHeader>
            <Table.ColumnHeader width="150px">Category</Table.ColumnHeader>
            <Table.ColumnHeader width="100px">Difficulty</Table.ColumnHeader>
            <Table.ColumnHeader width="120px" isNumeric>
              Passing Rate
            </Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredScenarios.map((scenario, index) => (
            <Table.Row
              key={scenario.id}
              bg={index % 2 === 0 ? "white" : "gray.50"}
              _hover={{ bg: "blue.50", cursor: "pointer" }}
              onClick={() =>
                navigate("/scenarios", {
                  state: { preSelectedScenario: scenario },
                })
              }
            >
              <Table.Cell><Flex justify="center" w="full">{getStatusIcon(scenario.status)}</Flex></Table.Cell>
              <Table.Cell
                maxW="400px"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                <Text>{scenario.title}</Text>
              </Table.Cell>
              <Table.Cell>
                {scenario.category === "Uncategorized" ? "N/A" : scenario.category}
              </Table.Cell>
              <Table.Cell>
                <Text color={getDifficultyColor(scenario.difficulty)} fontWeight="medium">
                  {scenario.difficulty}
                </Text>
              </Table.Cell>
              <Table.Cell isNumeric>{`${scenario.passing_rate}%`}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      {filteredScenarios.length === 0 && (
        <Box textAlign="center" py={8}>
          <Text color="gray.500">No scenarios found matching your filters</Text>
        </Box>
      )}
    </Box>
  );
}

ScenarioList.propTypes = {
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string,
      difficulty: PropTypes.string,
      status: PropTypes.string,
      passed: PropTypes.bool,
      passing_rate: PropTypes.number,
    })
  ),
};
