import axios from "axios";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../contexts/AuthContext";
import { useSimulationMode } from "../contexts/SimulationModeContext";
import { useParams } from "react-router-dom";
import config from "../config";

const { API_BASE_URL } = config;

export const useAnomalySimulationEnding = () => {
  const { user } = useAuth();
  const [sessionId] = useState(() => uuidv4());
  const { scenarioId } = useParams();
  const { currentMode } = useSimulationMode();

  const endSimulation = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/end_simulation_anomaly`, {
        sessionId,
        userId: user?.sub,
        scenarioId,
        mode: currentMode.id,
      });
    } catch (error) {
      console.error("Error ending simulation (anomaly):", error);
    }
  };
  return endSimulation;
};
