import React from 'react';
import {
  Box,
  VStack,
  Flex,
  Text,
  Grid,
  Badge,
  HStack,
  Separator,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaUserMd, FaClock } from 'react-icons/fa';

export default function DashboardProfile({ 
  userStats, 
  trainingStats, 
  recentActivity 
}) {
  const navigate = useNavigate();
  return (
    <VStack spacing={6} align="stretch">
      <Box bg="white" p={6} borderRadius="lg" shadow="sm">
        {/* Profile Header */}
        <Flex align="center" gap={4} mb={6}>
          <Box bg="gray.100" p={2} borderRadius="full">
            <FaUserMd size={24} />
          </Box>
          <VStack align="start" spacing={0}>
            <Text fontWeight="bold" fontSize="lg">
              {userStats?.profile?.display_name || "User"}
            </Text>
            <Text color="gray.600">
              {userStats?.profile?.user_tier || "Free Tier"}
            </Text>
          </VStack>
        </Flex>

        

        {/* Training Stats */}
        <Text fontSize="sm" fontWeight="bold" mb={3}>
          Training Completed
        </Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={2} mb={6}>
          <VStack bg="gray.50" p={2} borderRadius="md">
            <Text fontSize="sm" fontWeight="bold">
              {trainingStats?.practiceMode || 0}
            </Text>
            <Text fontSize="xs">Practice</Text>
          </VStack>
          <VStack bg="gray.50" p={2} borderRadius="md">
            <Text fontSize="sm" fontWeight="bold">
              {trainingStats?.examMode || 0}
            </Text>
            <Text fontSize="xs">Exam</Text>
          </VStack>
          <VStack bg="gray.50" p={2} borderRadius="md">
            <Text fontSize="sm" fontWeight="bold">
              {trainingStats?.studyMode || 0}
            </Text>
            <Text fontSize="xs">Study</Text>
          </VStack>
        </Grid>

        {/* Study Record Panel */}
        <Box 
          p={4} 
          bg="blue.50" 
          borderRadius="md" 
          mb={6}
          cursor="pointer"
          onClick={() => navigate('/study-history')}
          _hover={{ bg: "blue.100" }}
        >
          <Flex justify="space-between" align="center">
            <VStack align="start" spacing={1}>
              <Text fontSize="sm" fontWeight="bold" color="blue.700">
                Study Record
              </Text>
              <Text fontSize="xs" color="blue.600">
                View your complete study history
              </Text>
            </VStack>
            <Button 
              size="sm" 
              colorPalette="blue"
              variant="solid"
            >
              View All
            </Button>
          </Flex>
        </Box>

        {/* Recent Activity */}
        <Text fontSize="sm" fontWeight="bold" mb={3}>
          Recent Activity
        </Text>
        <VStack spacing={3} align="stretch">
          {recentActivity?.map((activity, index) => (
            <Box
              key={index}
              p={3}
              borderRadius="md"
              bg="gray.50"
              fontSize="sm"
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
            >
              <Flex justify="space-between" mb={1}>
                <Text fontWeight="medium">{activity.title}</Text>
                <Text color="green.500" fontWeight="bold">
                  {activity.score}%
                </Text>
              </Flex>
              <Flex justify="space-between" color="gray.600" fontSize="xs">
                <HStack spacing={1}>
                  <FaClock />
                  <Text>{activity.time}</Text>
                </HStack>
                <Text>{activity.type}</Text>
              </Flex>
            </Box>
          ))}
        </VStack>
      </Box>
    </VStack>
  );
}