import React from 'react';
import {
  Box, Heading, Text, Grid, VStack,
  Flex, useColorModeValue
} from '@chakra-ui/react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid,
  Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import { Button } from '../ui/button';
import { Alert } from '../ui/alert';
import { Checkbox } from '../ui/checkbox';

// Mock data for scenario progress
const mockScenarioProgress = {
    "Diabetes Medication Consultation": [
      { attempt: 1, date: '2025-02-01', score: 78 },
      { attempt: 2, date: '2025-02-05', score: 72 },
      { attempt: 3, date: '2025-02-12', score: 85 },
      { attempt: 4, date: '2025-02-20', score: 0 },
      { attempt: 5, date: '2025-03-01', score: 92 },
      { attempt: 6, date: '2025-03-15', score: 95 }
    ],
    "Athlete's Foot Consultation": [
      { attempt: 1, date: '2025-02-03', score: 68 },
      { attempt: 2, date: '2025-02-10', score: 75 },
      { attempt: 3, date: '2025-02-25', score: 82 },
      { attempt: 4, date: '2025-03-10', score: 88 }
    ],
    "Migraine Management": [
      { attempt: 1, date: '2025-02-05', score: 70 },
      { attempt: 2, date: '2025-02-15', score: 76 },
      { attempt: 3, date: '2025-03-01', score: 85 },
      { attempt: 4, date: '2025-03-20', score: 89 },
      { attempt: 5, date: '2025-04-05', score: 91 }
    ],
    "Hypertension Medication Review": [
      { attempt: 1, date: '2025-02-08', score: 65 },
      { attempt: 2, date: '2025-02-18', score: 73 },
      { attempt: 3, date: '2025-03-05', score: 79 },
      { attempt: 4, date: '2025-03-25', score: 85 },
      { attempt: 5, date: '2025-04-10', score: 90 },
      { attempt: 6, date: '2025-04-20', score: 93 },
      { attempt: 7, date: '2025-04-30', score: 95 }
    ],
    "Asthma Inhaler Technique": [
      { attempt: 1, date: '2025-02-12', score: 75 },
      { attempt: 2, date: '2025-03-01', score: 82 },
      { attempt: 3, date: '2025-03-18', score: 88 },
      { attempt: 4, date: '2025-04-05', score: 92 }
    ],
    "Anticoagulation Counseling": [
      { attempt: 1, date: '2025-02-15', score: 68 },
      { attempt: 2, date: '2025-02-28', score: 74 },
      { attempt: 3, date: '2025-03-10', score: 80 },
      { attempt: 4, date: '2025-03-25', score: 86 },
      { attempt: 5, date: '2025-04-15', score: 90 },
      { attempt: 6, date: '2025-04-28', score: 94 }
    ],
    "Chronic Pain Management": [
      { attempt: 1, date: '2025-02-20', score: 71 },
      { attempt: 2, date: '2025-03-05', score: 77 },
      { attempt: 3, date: '2025-03-20', score: 83 },
      { attempt: 4, date: '2025-04-10', score: 88 },
      { attempt: 5, date: '2025-04-25', score: 92 }
    ],
    "Smoking Cessation Counseling": [
      { attempt: 1, date: '2025-02-25', score: 73 },
      { attempt: 2, date: '2025-03-10', score: 79 },
      { attempt: 3, date: '2025-03-28', score: 85 },
      { attempt: 4, date: '2025-04-15', score: 90 }
    ],
    "Depression Medication Review": [
      { attempt: 1, date: '2025-03-01', score: 70 },
      { attempt: 2, date: '2025-03-15', score: 76 },
      { attempt: 3, date: '2025-03-30', score: 82 },
      { attempt: 4, date: '2025-04-10', score: 87 },
      { attempt: 5, date: '2025-04-25', score: 91 }
    ],
    "Pediatric Medication Consultation": [
      { attempt: 1, date: '2025-03-05', score: 69 },
      { attempt: 2, date: '2025-03-20', score: 75 },
      { attempt: 3, date: '2025-04-05', score: 82 },
      { attempt: 4, date: '2025-04-20', score: 88 },
      { attempt: 5, date: '2025-04-30', score: 93 }
    ]
  };
// Colors for different scenarios
const scenarioColors = [
  '#3182CE', // blue
  '#38A169', // green
  '#D53F8C', // pink
  '#805AD5', // purple
  '#DD6B20'  // orange
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box 
        bg="white" 
        p={3} 
        borderRadius="md" 
        border="1px solid" 
        borderColor="gray.200"
        boxShadow="sm"
      >
        <Text fontWeight="bold" mb={2}>
          Date: {label}
        </Text>
        {payload.map((entry, index) => (
          <Box key={index} mb={index < payload.length - 1 ? 2 : 0}>
            <Text color={entry.stroke}>
              <Text as="span" fontWeight="bold">{entry.name}</Text>
              <br />
              Score: {entry.value}%
            </Text>
          </Box>
        ))}
      </Box>
    );
  }
  return null;
};

const MAX_SELECTIONS = 5;

export default function ScenarioAnalytics({ selectedScenarios, onScenarioToggle }) {
  const scenarios = Object.keys(mockScenarioProgress);
  const hasSelectedScenarios = selectedScenarios.length > 0;

  // Handle (un)checking individual scenario
  const handleScenarioCheck = (scenario) => {
    if (selectedScenarios.includes(scenario)) {
      onScenarioToggle(selectedScenarios.filter(s => s !== scenario));
    } else if (selectedScenarios.length < MAX_SELECTIONS) {
      onScenarioToggle([...selectedScenarios, scenario]);
    }
  };

  // Clear all selected scenarios
  const handleClearAll = () => {
    onScenarioToggle([]);
  };
  
  return (
    <Box bg="white" p={6} borderRadius="lg" shadow="sm">
      <Grid templateColumns="1fr auto" gap={6}>
        {/* Chart Section */}
        <Box>
      <Heading size="md" mb={4}>Scenario Progress Analysis</Heading>
          {hasSelectedScenarios ? (
            <Box h="400px" w="100%">
        <ResponsiveContainer width="100%" height="100%">
                <LineChart margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" type="category" />
            <YAxis domain={[0, 100]} />
                  <Tooltip 
                    content={<CustomTooltip />}
                    formatter={(value, name) => [`${value}%`, name]} 
                  />
            <Legend />
                  {selectedScenarios.map((scenario, index) => (
            <Line 
                      key={scenario}
                      data={mockScenarioProgress[scenario]}
              type="monotone" 
              dataKey="score" 
                      name={scenario}
                      stroke={scenarioColors[index % scenarioColors.length]}
              strokeWidth={2}
              dot={{ r: 6 }}
              activeDot={{ r: 8 }}
            />
                  ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
          ) : (
            <Box py={8} textAlign="center">
              <Text color="gray.500">Select scenarios to view analysis</Text>
            </Box>
          )}
        </Box>

        {/* Scenario Selector */}
        <Box w="300px">
          <Flex justify="space-between" align="center" mb={3}>
            <Text fontWeight="bold">Compare Scenarios</Text>
            {hasSelectedScenarios && (
              <Button 
                size="xs"
                variant="ghost"
                colorPalette="gray"
                onClick={handleClearAll}
              >
                Clear All
              </Button>
            )}
          </Flex>

          {selectedScenarios.length === MAX_SELECTIONS && (
            <Alert status="info" size="sm" mb={3} borderRadius="md">
              Maximum {MAX_SELECTIONS} scenarios can be compared
            </Alert>
          )}

          {/* Scrollable Scenario List */}
          <Box 
            maxH="200px" 
            overflowY="auto" 
            borderRadius="md" 
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack align="stretch" spacing={0}>
              {scenarios.map((scenario) => {
                const isSelected = selectedScenarios.includes(scenario);
                const isDisabled = !isSelected && selectedScenarios.length >= MAX_SELECTIONS;

                return (
                  <Box
                    key={scenario}
                    px={3}
                    py={2}
                    _hover={{ bg: 'gray.50' }}
                    opacity={isDisabled ? 0.5 : 1}
                  >
                    <Checkbox
                      checked={isSelected}
                      onCheckedChange={() => handleScenarioCheck(scenario)}
                      disabled={isDisabled}
                      colorPalette="blue"
                    >
                      <Text fontSize="sm" noOfLines={1}>{scenario}</Text>
                    </Checkbox>
                  </Box>
                );
              })}
          </VStack>
          </Box>

          {/* Statistics */}
          {hasSelectedScenarios && (
            <Box mt={6}>
              <Text fontWeight="bold" mb={3}>Statistics</Text>
              <VStack align="stretch" spacing={2}>
              {selectedScenarios.map((scenario) => {
                const data = mockScenarioProgress[scenario];
                const averageScore = Math.round(
                  data.reduce((acc, curr) => acc + curr.score, 0) / data.length
                );
                const bestScore = Math.max(...data.map(d => d.score));

                return (
                  <Box 
                    key={scenario} 
                    p={3} 
                    bg="gray.50" 
                    borderRadius="md" 
                  >
                      <Text fontWeight="medium" fontSize="sm" noOfLines={1}>
                        {scenario}
                      </Text>
                    <Grid templateColumns="1fr 1fr 1fr" gap={2} mt={1}>
          <Box>
                        <Text fontSize="xs" color="gray.600">Attempts</Text>
                        <Text fontSize="sm" fontWeight="bold">{data.length}</Text>
          </Box>
          <Box>
                        <Text fontSize="xs" color="gray.600">Best</Text>
                        <Text fontSize="sm" fontWeight="bold">{bestScore}%</Text>
          </Box>
          <Box>
                        <Text fontSize="xs" color="gray.600">Average</Text>
                        <Text fontSize="sm" fontWeight="bold">{averageScore}%</Text>
          </Box>
                    </Grid>
                  </Box>
                );
              })}
              </VStack>
            </Box>
          )}
      </Box>
      </Grid>
    </Box>
  );
}