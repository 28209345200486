import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Input,
  Flex,
  Spinner,
  Badge,
} from '@chakra-ui/react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableColumnHeader,
} from '../ui/table';
import {
  SelectRoot,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValueText,
} from '../ui/select';
import { Button } from '../ui/button';
import { Search, Calendar } from 'lucide-react';
import { Toaster, toaster } from '../ui/toaster';
import ScenarioAnalytics from './ScenarioAnalytics';
import { useAuth } from '../../contexts/AuthContext';
import config from '../../config';

// Presentational Components
const Header = ({ onBackClick }) => (
  <Flex justify="space-between" align="center">
    <Heading size="lg" color="blue.600">Study History</Heading>
    <Button onClick={onBackClick} variant="outline" colorPalette="blue">
      Back to Dashboard
    </Button>
  </Flex>
);

const TotalSimulationsCard = ({ total }) => (
  <HStack spacing={8} bg="blue.50" p={4} borderRadius="lg">
    <Box>
      <Text fontSize="sm" color="gray.600">Total Simulations</Text>
      <Text fontSize="2xl" fontWeight="bold">{total}</Text>
    </Box>
  </HStack>
);

const Filters = ({ searchTerm, onSearchChange, dateFilter, onDateFilterChange }) => (
  <HStack spacing={4}>
    <Box flex={1}>
      <HStack>
        <Box position="relative" flex={1}>
          <Input
            placeholder="Search scenarios..."
            value={searchTerm}
            onChange={onSearchChange}
            pl={10}
          />
          <Box position="absolute" left={3} top="50%" transform="translateY(-50%)">
            <Search size={20} className="text-gray-400" />
          </Box>
        </Box>
        <SelectRoot value={dateFilter} onValueChange={onDateFilterChange} w="200px">
          <SelectTrigger>
            <HStack spacing={2}>
              <Calendar size={20} />
              <SelectValueText placeholder="Select time range" />
            </HStack>
          </SelectTrigger>
          <SelectContent>
            <SelectItem item={{ value: "all", label: "All Time" }}>All Time</SelectItem>
            <SelectItem item={{ value: "week", label: "This Week" }}>This Week</SelectItem>
            <SelectItem item={{ value: "month", label: "This Month" }}>This Month</SelectItem>
            <SelectItem item={{ value: "year", label: "This Year" }}>This Year</SelectItem>
          </SelectContent>
        </SelectRoot>
      </HStack>
    </Box>
  </HStack>
);

const SimulationRow = ({ simulation, onRowClick, onViewEvaluation }) => {
  const getScoreBadgeColor = (score) => {
    if (score >= 90) return 'green';
    if (score >= 80) return 'blue';
    if (score >= 70) return 'yellow';
    return 'red';
  };

  return (
    <TableRow
      onClick={() => onRowClick(simulation)}
      className="hover:bg-gray-50 cursor-pointer"
    >
      <TableCell>{simulation.date}</TableCell>
      <TableCell>{simulation.scenario}</TableCell>
      <TableCell>{simulation.duration}</TableCell>
      <TableCell>
        {simulation.score && (
          <Badge colorPalette={getScoreBadgeColor(simulation.score)} variant="subtle">
            {simulation.score}%
          </Badge>
        )}
      </TableCell>
      <TableCell>
        <Badge
          colorPalette={simulation.status === 'Completed' ? 'green' : 'yellow'}
          variant="subtle"
        >
          {simulation.status}
        </Badge>
      </TableCell>
      <TableCell>
        {simulation.status === 'Completed' && (
          <Button
            size="sm"
            variant="ghost"
            colorPalette="blue"
            onClick={(e) => {
              e.stopPropagation();
              onViewEvaluation(simulation);
            }}
          >
            View Evaluation
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  totalPages > 1 && (
    <Flex justify="center" gap={2}>
      <Button
        variant="outline"
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <Text alignSelf="center">
        Page {currentPage} of {totalPages}
      </Text>
      <Button
        variant="outline"
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </Flex>
  )
);

// Main Component
export default function StudyHistory() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isFirstLoad = React.useRef(true);
  const PAGE_SIZE = 10;

  const [filters, setFilters] = useState({
    searchTerm: '',
    dateFilter: 'all',
    page: 1
  });
  
  const [data, setData] = useState({
    simulations: [],
    selectedScenarios: [],
    totalPages: 1,
    totalSimulations: 0,
    loading: true,
    error: null
  });

  const fetchSimulations = async () => {
    if (!user?.sub) return;
    
  if (isFirstLoad.current) {
      setData(prev => ({ ...prev, loading: true }));
  }

    try {
      const queryParams = new URLSearchParams({
        user_id: user.sub,
        page: filters.page,
        page_size: PAGE_SIZE,
        ...(filters.searchTerm && { search: filters.searchTerm }),
        ...(filters.dateFilter !== 'all' && { date_filter: filters.dateFilter })
      });
      
      const response = await fetch(`${config.API_BASE_URL}/api/study-history?${queryParams}`);
      if (!response.ok) throw new Error('Failed to fetch simulation history');
      
      const result = await response.json();
      setData(prev => ({
        ...prev,
        simulations: result.records,
        totalPages: result.pages,
        totalSimulations: result.total,
        error: null
      }));
    } catch (err) {
      setData(prev => ({ ...prev, error: err.message }));
      toaster.create({
        title: 'Error',
        description: 'Failed to load simulation history',
        type: 'error',
        duration: 5000,
      });
    } finally {
    if (isFirstLoad.current) {
        setData(prev => ({ ...prev, loading: false }));
      isFirstLoad.current = false;
    }
    }
  };

  // Fetch data when filters change
  useEffect(() => {
    if (sessionStorage.getItem('study_history_state') && data.simulations.length > 0) {
    return;
  }
    const debounceTimer = setTimeout(fetchSimulations, 500);
    return () => clearTimeout(debounceTimer);
  }, [filters, user?.sub]);

  const handleViewEvaluation = (simulation) => {
    if (!simulation.feedback_log) {
      toaster.create({
        title: 'Error',
        description: 'No feedback available for this simulation',
        type: 'error',
        duration: 3000,
      });
      return;
    }

    // Store the feedback log in session storage for the simulation result page
    sessionStorage.setItem(
      `simulation_feedback_${simulation.id}`, 
      JSON.stringify(simulation.feedback_log)
    );
    
    // Navigate to the simulation result page with source information
    navigate(`/simulation-result/${simulation.id}`, {
      state: { from: 'study-history' }
    });
  };

  const handleRowClick = (simulation) => {
    if (!data.selectedScenarios.includes(simulation.scenario) && 
        data.selectedScenarios.length < 5) {
      setData(prev => ({
        ...prev,
        selectedScenarios: [...prev.selectedScenarios, simulation.scenario]
      }));
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Toaster />
      <VStack spacing={6} align="stretch">
        <Header onBackClick={() => navigate('/dashboard')} />
        <TotalSimulationsCard total={data.totalSimulations} />
        <Filters
          searchTerm={filters.searchTerm}
          onSearchChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
          dateFilter={filters.dateFilter}
          onDateFilterChange={(value) => setFilters(prev => ({ ...prev, dateFilter: value }))}
        />

        <Box maxH="400px" overflowY="auto" bg="white" borderRadius="lg" shadow="sm">
          {data.loading ? (
            <Flex justify="center" align="center" h="200px">
              <Spinner size="xl" color="blue.500" />
            </Flex>
          ) : data.error ? (
            <Flex justify="center" align="center" h="200px">
              <Text color="red.500">{data.error}</Text>
            </Flex>
          ) : (
          <Table>
            <TableHeader position="sticky" top={0} bg="white" zIndex={1}>
            <TableRow>
                <TableColumnHeader>Date</TableColumnHeader>
                <TableColumnHeader>Scenario</TableColumnHeader>
                <TableColumnHeader>Duration</TableColumnHeader>
                <TableColumnHeader>Score</TableColumnHeader>
                <TableColumnHeader>Status</TableColumnHeader>
                <TableColumnHeader>Evaluation</TableColumnHeader>
            </TableRow>
            </TableHeader>
            <TableBody>
                {data.simulations.map((simulation) => (
                  <SimulationRow
                  key={simulation.id}
                    simulation={simulation}
                    onRowClick={handleRowClick}
                    onViewEvaluation={handleViewEvaluation}
                  />
              ))}
            </TableBody>
          </Table>
          )}
        </Box>

        <Pagination
          currentPage={filters.page}
          totalPages={data.totalPages}
          onPageChange={(page) => setFilters(prev => ({ ...prev, page }))}
        />

                <ScenarioAnalytics 
          selectedScenarios={data.selectedScenarios}
          onScenarioToggle={(scenarios) => setData(prev => ({
            ...prev,
            selectedScenarios: scenarios
          }))}
                />
      </VStack>
    </Container>
  );
}