import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Box,
  Card,
  Heading,
  HStack,
  IconButton,
  List,
  Separator,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";

export const DetailedFeedbackCard = ({ sections }) => {
  const sectionsArray = Object.entries(sections || {}).map(
    ([sectionName, items]) => ({
      section_name: sectionName,
      items: items,
    })
  );

  const renderCriterion = (item) => (
    <List.Root variant="plain" align="center">
      <List.Item key={item.criteria} mb={2}>
        <List.Indicator>
          {item.met ? (
            <IconButton
              rounded="full"
              colorPalette="green"
              bg="green.400"
              size="2xs"
            >
              <FiCheckCircle />
            </IconButton>
          ) : (
            <IconButton
              rounded="full"
              colorPalette="red"
              bg="red.400"
              size="2xs"
            >
              <RiCloseFill />
            </IconButton>
          )}
        </List.Indicator>
        <Box width="100%" ml={2}>
          <HStack width="100%" align="center">
            <Text fontWeight="bold">{item.criteria}</Text>
            {item.critical && (
              <Badge colorPalette="red" variant="subtle" fontSize="xs">
                Critical
              </Badge>
            )}
          </HStack>
          <Text fontSize="sm" color="gray.600">
            {item.justification}
          </Text>
        </Box>
      </List.Item>
      <Separator />
    </List.Root>
  );

  return (
    <Card.Root>
      <Card.Header bg="blue.50" p={4}>
        <Heading size="md">Detailed Evaluation</Heading>
      </Card.Header>
      <Card.Body>
        <Tabs.Root defaultValue={sectionsArray[0].section_name.toLowerCase()}>
          <Tabs.List>
            {sectionsArray.map((section, index) => (
              <Tabs.Trigger
                key={index}
                _selected={{ color: "blue.500", borderColor: "blue.500" }}
                value={section.section_name.toLowerCase()}
              >
                {section.section_name}
              </Tabs.Trigger>
            ))}
          </Tabs.List>

          {sectionsArray.map((section, index) => (
            <Tabs.Content
              key={index}
              value={section.section_name.toLowerCase()}
            >
              <VStack align="stretch">
                {section.items.map(renderCriterion)}
              </VStack>
            </Tabs.Content>
          ))}
        </Tabs.Root>
      </Card.Body>
    </Card.Root>
  );
};

DetailedFeedbackCard.propTypes = {
  sections: PropTypes.object.isRequired,
};
