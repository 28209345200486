import React from "react";
import PropTypes from "prop-types";
import { Card, Heading, Icon, List, Text } from "@chakra-ui/react";
import { BsStarFill } from "react-icons/bs";

export const RecommendationsCard = ({ recommendations }) => {
  return (
    <Card.Root>
      <Card.Header>
        <Heading size="md">Key Recommendations</Heading>
      </Card.Header>
      <Card.Body>
        {Array.isArray(recommendations) && recommendations.length > 0 ? (
          <List.Root spacing={3}>
            {recommendations.map((rec, i) => (
              <List.Item key={i} display="flex" alignItems="flex-start">
                <Icon color="teal.500" mr={2} mt={1}>
                  <BsStarFill />
                </Icon>
                <Text>{rec}</Text>
              </List.Item>
            ))}
          </List.Root>
        ) : (
          <Text>No recommendations available.</Text>
        )}
      </Card.Body>
    </Card.Root>
  );
};

RecommendationsCard.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.string).isRequired,
};
