'use client'

import { Table as ChakraTable } from '@chakra-ui/react'
import * as React from 'react'

export const Table = React.forwardRef(function Table(props, ref) {
  return <ChakraTable.Root ref={ref} {...props} />
})

export const TableHeader = React.forwardRef(function TableHeader(props, ref) {
  return <ChakraTable.Header ref={ref} {...props} />
})

export const TableBody = React.forwardRef(function TableBody(props, ref) {
  return <ChakraTable.Body ref={ref} {...props} />
})

export const TableFooter = React.forwardRef(function TableFooter(props, ref) {
  return <ChakraTable.Footer ref={ref} {...props} />
})

export const TableRow = React.forwardRef(function TableRow(props, ref) {
  return <ChakraTable.Row ref={ref} {...props} />
})

export const TableCell = React.forwardRef(function TableCell(props, ref) {
  return <ChakraTable.Cell ref={ref} {...props} />
})

export const TableColumnHeader = React.forwardRef(function TableColumnHeader(props, ref) {
  return <ChakraTable.ColumnHeader ref={ref} {...props} />
})

export const TableCaption = React.forwardRef(function TableCaption(props, ref) {
  return <ChakraTable.Caption ref={ref} {...props} />
})