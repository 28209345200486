// src/components/StudyPlan/StudyPlanWrapper.jsx
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import PatientCounselingPlan from './StudyPlanPage';

const plans = {
  'patient-counseling': PatientCounselingPlan,
  // Add other study plan components here as they're created
  // 'calculations': PharmacyCalculationsPlan,
  // 'communications': CommunicationsPlan,
  // etc...
};

export const StudyPlanWrapper = () => {
  const { planId } = useParams();
  const PlanComponent = plans[planId];
  
  if (!PlanComponent) {
    return <Navigate to="/dashboard" replace />;
  }

  return <PlanComponent />;
};

export default StudyPlanWrapper;