import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Heading,
  Separator,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "../ui/radio";
import { Tag } from "../ui/tag";
import { useSimulationMode } from "../../contexts/SimulationModeContext";
import { toaster } from "../ui/toaster";
import { useNavigate } from "react-router-dom";

export const ScenarioDetail = ({ selectedScenario }) => {
  const [selectedMode, setSelectedMode] = useState("practice");
  const { setCurrentMode, SIMULATION_MODES } = useSimulationMode();
  const navigate = useNavigate();

  const handleStartScenario = () => {
    if (selectedScenario) {
      const mode = SIMULATION_MODES[selectedMode];
      if (!mode) {
        toaster.create({
          title: "Error",
          description: "Please select a valid simulation mode",
          type: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      setCurrentMode(mode);
      navigate(`/simulation/${selectedScenario.id}`);
    } else {
      toaster.create({
        title: "Select a Scenario",
        description: "Please select a scenario to start.",
        type: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card.Root p={6} variant={"elevated"} w={"40%"}>
      {selectedScenario ? (
        <VStack align="start" spacing={4}>
          <Heading as="h3" size="lg" color="blue.500">
            {selectedScenario.title}
          </Heading>
          <Text>
            <strong>Category:</strong>{" "}
            {selectedScenario.category || "Uncategorized"}
          </Text>
          <Text>
            <strong>Description:</strong> {selectedScenario.description}
          </Text>

          <Box>
            {selectedScenario.tags?.map((tag, index) => (
              <Tag key={index} colorPalette="blue" mr={2}>
                {tag}
              </Tag>
            ))}
          </Box>
          <Separator />
          <RadioGroup
            onValueChange={(e) => setSelectedMode(e.value)}
            value={selectedMode}
            colorPalette={"blue"}
          >
            <Stack spacing={4}>
              <Radio value="practice">Practice Mode</Radio>
              <Radio value="exam">Exam Mode</Radio>
              <Radio value="demo">Demo Mode</Radio>
            </Stack>
          </RadioGroup>
          <Button colorPalette="blue" size="lg" onClick={handleStartScenario}>
            Start Scenario
          </Button>
        </VStack>
      ) : (
        <Text>Select a scenario to view details</Text>
      )}
    </Card.Root>
  );
};

ScenarioDetail.propTypes = {
  selectedScenario: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }),
};
