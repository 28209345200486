import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  Heading,
  HStack,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  FaCheckCircle,
  FaClock,
  FaHistory,
  FaStar,
  FaTrophy,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster, toaster } from "../ui/toaster";
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from "../ui/dialog";

export default function ExamModeHome() {
  const location = useLocation();
  const userTier = location.state?.userTier;
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSet, setSelectedSet] = useState(null);

  const hasPremiumAccess = () => {
    return userTier === "beta" || userTier === "premium";
  };

  // Mock exam sets data
  const examSets = [
    {
      id: 1,
      title: "OSCE Mock Exam Set 1",
      completionRate: 85,
      averageScore: 78,
      timeLimit: 105,
      attempts: 2,
      maxAttempts: 3,
      isCompleted: true,
      bestScore: 82,
      lastAttemptDate: "2024-03-15",
      scenarios: Array(7).fill("Hidden Scenario"),
    },
    {
      id: 2,
      title: "OSCE Mock Exam Set 2",
      completionRate: 65,
      averageScore: 72,
      timeLimit: 105,
      attempts: 1,
      maxAttempts: 3,
      isCompleted: false,
      bestScore: 75,
      lastAttemptDate: "2024-03-10",
      scenarios: Array(7).fill("Hidden Scenario"),
    },
    {
      id: 3,
      title: "OSCE Set 3",
      completionRate: 45,
      averageScore: 68,
      timeLimit: 105,
      attempts: 0,
      maxAttempts: 3,
      isCompleted: false,
      scenarios: Array(7).fill("Hidden Scenario"),
    },
    // Add more exam sets as needed
  ];

  const handleStartExam = (examSet) => {
    if (examSet.isPremium && !hasPremiumAccess()) {
      toaster.create({
        title: "Premium Feature",
        description: "Please upgrade to access this feature",
        type: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setSelectedSet(examSet);
    onOpen();
  };

  const confirmStartExam = () => {
    onClose();
    // Navigate to exam with selected set
    navigate(`/exam-mode/${selectedSet.id}`);
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty.toLowerCase()) {
      case "easy":
        return "green";
      case "medium":
        return "yellow";
      case "hard":
        return "orange";
      case "expert":
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Toaster />
      {/* Header Section */}
      <VStack spacing={6} align="stretch">
        <Box
          bgGradient="to-r" gradientFrom="blue.400" gradientTo="purple.500"
          p={6}
          borderRadius="lg"
          color="white"
        >
          <Heading size="xl" mb={2}>
            OSCE Exam Mode
          </Heading>
          <Text fontSize="lg">
            Simulate real OSCE exam conditions with timed sets of scenarios.
          </Text>
        </Box>

        {/* Stats Overview */}
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
          <Box
            p={4}
            bg="white"
            borderRadius="lg"
            shadow="lg"
            textAlign="center"
          >
            <VStack>
              <Icon color="blue.500" boxSize={8}>
                <FaHistory />
              </Icon>
              <Text fontWeight="bold">Total Attempts</Text>
              <Text fontSize="2xl" color="blue.500">
                24
              </Text>
            </VStack>
          </Box>
          <Box
            p={4}
            bg="white"
            borderRadius="lg"
            shadow="lg"
            textAlign="center"
          >
            <VStack>
              <Icon color="yellow.500" boxSize={8}>
                <FaTrophy />
              </Icon>
              <Text fontWeight="bold">Best Score</Text>
              <Text fontSize="2xl" color="yellow.500">
                85%
              </Text>
            </VStack>
          </Box>
          <Box
            p={4}
            bg="white"
            borderRadius="lg"
            shadow="lg"
            textAlign="center"
          >
            <VStack>
              <Icon color="green.500" boxSize={8}>
                <FaCheckCircle />
              </Icon>
              <Text fontWeight="bold">Sets Completed</Text>
              <Text fontSize="2xl" color="green.500">
                2/5
              </Text>
            </VStack>
          </Box>
          <Box
            p={4}
            bg="white"
            borderRadius="lg"
            shadow="lg"
            textAlign="center"
          >
            <VStack>
              <Icon color="purple.500" boxSize={8}>
                <FaClock />
              </Icon>
              <Text fontWeight="bold">Avg. Completion</Text>
              <Text fontSize="2xl" color="purple.500">
                98m
              </Text>
            </VStack>
          </Box>
        </Grid>

        {/* Exam Sets List */}
        <VStack spacing={4} align="stretch">
          {examSets.map((examSet) => (
            <Box
              key={examSet.id}
              p={6}
              bgGradient="to-r" gradientFrom="white" gradientTo="blue.50"
              borderRadius="lg"
              shadow="lg"
              _hover={{ transform: "scale(1.02)", shadow: "xl" }}
              transition="all 0.3s"
              position="relative"
            >
              {/* Header */}
              <Grid templateColumns="1fr auto" gap={4} mb={4}>
                <VStack align="start" spacing={2}>
                  <Heading size="md">{examSet.title}</Heading>
                  <HStack spacing={4}>
                    <Text color="gray.600">
                      <Icon mr={2}>
                        <FaClock />
                      </Icon>
                      {examSet.timeLimit} minutes
                    </Text>
                    <Text color="gray.600">
                      <Icon mr={2}>
                        <FaStar />
                      </Icon>
                      {examSet.averageScore}% avg score
                    </Text>
                    <Text color="gray.600">
                      Attempts: {examSet.attempts}/{examSet.maxAttempts}
                    </Text>
                  </HStack>
                </VStack>
                <Button
                  colorPalette="blue"
                  size="lg"
                  onClick={() => handleStartExam(examSet)}
                >
                  Start Exam
                </Button>
              </Grid>

              {/* Best Score and Last Attempt */}
              {examSet.attempts > 0 && (
                <HStack spacing={4} mb={4}>
                  <Badge colorPalette="green">
                    Best Score: {examSet.bestScore}%
                  </Badge>
                  <Text fontSize="sm" color="gray.500">
                    Last attempt: {examSet.lastAttemptDate}
                  </Text>
                </HStack>
              )}

              {/* Scenarios Preview */}
              <Grid templateColumns="repeat(7, 1fr)" gap={2}>
                {examSet.scenarios.map((scenario, index) => (
                  <Tooltip key={index} label={scenario} placement="top">
                    <Box
                      p={2}
                      bg="gray.50"
                      borderRadius="md"
                      textAlign="center"
                      fontSize="sm"
                    >
                      Station {index + 1}
                    </Box>
                  </Tooltip>
                ))}
              </Grid>
            </Box>
          ))}
        </VStack>
      </VStack>

      {/* Confirmation Modal */}
      <DialogRoot isOpen={isOpen} onClose={onClose}>
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>Start OSCE Exam</DialogHeader>
          <DialogBody>
            <Alert status="info" mb={4}>
              <VStack align="start" spacing={2}>
                <Text>
                  You are about to start a timed OSCE exam simulation:
                </Text>
                <Text>• Duration: {selectedSet?.timeLimit} minutes</Text>
                <Text>• 7 consecutive stations</Text>
                <Text>• No breaks between stations</Text>
                <Text>• Cannot return to previous stations</Text>
              </VStack>
            </Alert>
            <Text color="gray.600">
              Are you ready to begin? Make sure you have a quiet environment and
              won&apos;t be interrupted.
            </Text>
          </DialogBody>
          <DialogFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorPalette="blue" onClick={confirmStartExam}>
              Start Exam
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </Container>
  );
}
