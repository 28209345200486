import { React } from "react";

import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useAnomalySimulationEnding } from "../../api/scenarios";
import { Button } from "../ui/button";
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTrigger,
} from "../ui/dialog";

export const GoBackToScenarioDialog = () => {
  const navigate = useNavigate();
  const handleAnomalyEnding = useAnomalySimulationEnding();
  const handleGoBackToScenario = () => {
    handleAnomalyEnding();
    navigate("/scenarios");
  };

  return (
    <DialogRoot isCentered>
      <DialogBackdrop />
      <DialogTrigger>
        <Button colorPalette="gray" width="100%" variant="subtle">
          <IoArrowBack />
          Scenario list
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          Go Back to Scenarios
        </DialogHeader>
        <DialogBody>
          Are you sure you want to go back to scenario selection? This will end
          the current simulation.
        </DialogBody>
        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button>Cancel</Button>
          </DialogActionTrigger>
          <Button colorPalette="red" onClick={handleGoBackToScenario}>
            Go Back
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
