import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  Button,
  Grid,
} from "@chakra-ui/react";
import { CheckCircle, AlertCircle } from "lucide-react";
import { ProgressRoot, ProgressBar } from "../ui/progress";

const getDifficultyColor = (difficulty) => {
  switch (difficulty.toLowerCase()) {
    case "easy":
      return "green";
    case "medium":
      return "orange";
    case "hard":
      return "red";
    default:
      return "gray";
  }
};

const PatientCounselingPlan = () => {
  const studyPlan = {
    title: "Patient Counseling",
    description: "Essential scenarios and questions for OSCE preparation",
    stats: {
      totalScenarios: 25,
      completedScenarios: 0,
      totalTime: "15-20 hours",
      difficulty: "Progressive",
    },
    categories: [
      {
        title: "Medication Counseling Basics",
        scenarios: [
          {
            id: 1,
            title: "Antibiotic Usage Instructions",
            difficulty: "Easy",
            status: "Todo",
            duration: "20 min",
          },
          {
            id: 2,
            title: "Inhaler Technique Demonstration",
            difficulty: "Easy",
            status: "Todo",
            duration: "25 min",
          },
          {
            id: 3,
            title: "Blood Pressure Medication Review",
            difficulty: "Medium",
            status: "Todo",
            duration: "30 min",
          },
        ],
      },
      {
        title: "Chronic Disease Management",
        scenarios: [
          {
            id: 4,
            title: "Diabetes Management Counseling",
            difficulty: "Medium",
            status: "Todo",
            duration: "35 min",
          },
          {
            id: 5,
            title: "Hypertension Lifestyle Modifications",
            difficulty: "Medium",
            status: "Todo",
            duration: "30 min",
          },
        ],
      },
    ],
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack gap={6} align="stretch">
        <Box
          p={6}
          borderRadius="lg"
          boxShadow="sm"
          borderWidth="1px"
          className="light"
        >
          <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={6}>
            <Box>
              <Heading size="lg" mb={4}>
                {studyPlan.title}
              </Heading>
              <Text fontSize="md" mb={4}>
                {studyPlan.description}
              </Text>
              <HStack gap={6} mb={4}>
                <HStack>
                  <CheckCircle />
                  <Text>{studyPlan.stats.totalScenarios} Scenarios</Text>
                </HStack>
                <HStack>
                  <AlertCircle />
                  <Text>{studyPlan.stats.totalTime}</Text>
                </HStack>
              </HStack>
              <ProgressRoot colorPalette={"blue"} size="sm" value={0}>
                <ProgressBar />
              </ProgressRoot>
            </Box>
            <Box>
              <Box p={4} borderRadius="md" bg="gray.50" className="light">
                <Heading size="sm" mb={3}>
                  Study Plan Progress
                </Heading>
                <VStack align="stretch" gap={3}>
                  <HStack justify="space-between">
                    <Text>Completed:</Text>
                    <Text fontWeight="bold">
                      {studyPlan.stats.completedScenarios}/
                      {studyPlan.stats.totalScenarios}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text>Difficulty:</Text>
                    <Text fontWeight="bold">{studyPlan.stats.difficulty}</Text>
                  </HStack>
                </VStack>
              </Box>
            </Box>
          </Grid>
        </Box>

        {studyPlan.categories.map((category, idx) => (
          <Box
            key={idx}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth="1px"
            className="light"
          >
            <Heading size="md" mb={4}>
              {category.title}
            </Heading>
            <VStack gap={4} align="stretch">
              {category.scenarios.map((scenario) => (
                <Box
                  key={scenario.id}
                  p={4}
                  borderRadius="md"
                  borderWidth="1px"
                  transition="all 0.2s"
                >
                  <Grid
                    templateColumns={{ base: "1fr", md: "3fr 1fr 1fr" }}
                    gap={4}
                    alignItems="center"
                  >
                    <Box>
                      <HStack>
                        {scenario.status === "Completed" ? (
                          <CheckCircle color="green" />
                        ) : (
                          <AlertCircle color="red" />
                        )}
                        <Text fontWeight="medium">{scenario.title}</Text>
                      </HStack>
                    </Box>
                    <Box>
                      <HStack>
                        <Badge
                          colorPalette={getDifficultyColor(scenario.difficulty)}
                        >
                          {scenario.difficulty}
                        </Badge>
                        <Text fontSize="sm">{scenario.duration}</Text>
                      </HStack>
                    </Box>
                    <Box>
                      <Button size="sm" colorPalette="blue" width="full">
                        Start
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              ))}
            </VStack>
          </Box>
        ))}
      </VStack>
    </Container>
  );
};

export default PatientCounselingPlan;
