import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import config from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import Layout from "../Layout";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from "../ui/select";
import { Toaster } from "../ui/toaster";
import { ScenarioDetail } from "./ScenarioDetail";
import { ScenarioList } from "./ScenarioList";

function ScenarioSelection() {
  const { user } = useAuth();
  const location = useLocation();
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState([]);

  useEffect(() => {
    // Only fetch if we don't already have scenarios or if user changes
    if (!scenarios.length) {
      console.log("Fetching scenarios from API...");
      setLoading(true);
      const url = `${config.API_BASE_URL}/api/list_scenarios${
        user?.sub ? `?user_id=${user.sub}` : ""
      }`;

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          console.log("Received scenarios:", result);
          if (result.success) {
            setScenarios(result.data);

            // Handle pre-selected scenario
            if (location.state?.preSelectedScenario) {
              const matchingScenario = result.data.find(
                (scenario) =>
                  scenario.id === location.state.preSelectedScenario.id
              );
              if (matchingScenario) {
                setSelectedScenario(matchingScenario);
              }
            }
          } else {
            throw new Error(result.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error("Error fetching scenarios:", e);
          setError(e.message);
          setLoading(false);
        });
    }
  }, [user]);

  // Separate useEffect for handling pre-selected scenario
  useEffect(() => {
    if (location.state?.preSelectedScenario && scenarios.length > 0) {
      const matchingScenario = scenarios.find(
        (scenario) => scenario.id === location.state.preSelectedScenario.id
      );
      if (matchingScenario) {
        setSelectedScenario(matchingScenario);
      }
    }
  }, [location.state, scenarios]);

  const filteredScenarios = Array.isArray(scenarios)
    ? scenarios.filter(
        (scenario) =>
          scenario?.title?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (categoryFilter.length === 0 ||
            categoryFilter.includes(scenario.category))
      )
    : [];

  const categories = Array.isArray(scenarios)
    ? [...new Set(scenarios.map((s) => s.category))]
    : [];

  if (loading) {
    return (
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <Spinner size="xl" />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Text color="red.500">Error: {error}</Text>
      </Layout>
    );
  }

  return (
    <Layout>
      <Toaster />
      <Container maxW="container.lg">
        <Heading size="2xl" mb={6} textAlign="center" colorPalette="blue">
          Select a Scenario
        </Heading>

        <VStack spacing={6}>
          <Flex width="100%" spacing={4} align={"center"}>
            <Input
              placeholder="Search scenarios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="lg"
              mr={4}
              variant="filled"
              width={"500px"}
            />
            <SelectRoot
              closeOnSelect={false}
              items={categories}
              multiple
              onValueChange={(item) => setCategoryFilter(item.value)}
              size="lg"
              variant="plain"
              width="250px"
            >
              <SelectTrigger>
                <SelectValueText placeholder="Select category" />
              </SelectTrigger>
              <SelectContent>
                {categories.map((category) => (
                  <SelectItem item={category} key={category}>
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </Flex>

          <HStack spacing={4} align="start" width="100%">
            <ScenarioList
              filteredScenarios={filteredScenarios}
              onScenarioSelect={(scenario) => setSelectedScenario(scenario)}
            />
            <ScenarioDetail selectedScenario={selectedScenario} />
          </HStack>
        </VStack>
      </Container>
    </Layout>
  );
}

export default ScenarioSelection;
