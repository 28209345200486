import React, { useState } from "react";
import {
  Container,
  VStack,
  HStack,
  Input,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { userPool } from "../contexts/AuthContext";
import { Field } from "../components/ui/field";
import { PasswordInput } from "../components/ui/password-input";

export default function SignUp() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");

  const [formState, setFormState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    displayName: "",
    password: "",
    confirmPassword: "",
  });

  const {
    email,
    firstName,
    lastName,
    displayName,
    password,
    confirmPassword,
  } = formState;

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...formState, [name]: value };
    setFormState(updatedForm);

    // Real-time password validation
    if (name === "password") {
      const isValid = value.length >= 8 && /\d/.test(value) && /[a-zA-Z]/.test(value);
      setPasswordValid(isValid);
      // Also check password match when password changes
      setPasswordsMatch(value === updatedForm.confirmPassword);
    }
    
    // Real-time confirm password validation
    if (name === "confirmPassword") {
      setPasswordsMatch(value === updatedForm.password);
    }
  };

  const allFormsFilled = () => {
    const allFilled = Object.values(formState).every((val) => val.trim() !== "");
    return allFilled && passwordsMatch && passwordValid;
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setErrorMsg("");

      const attributes = [
        new CognitoUserAttribute({ Name: "email", Value: email }),
        new CognitoUserAttribute({ Name: "given_name", Value: firstName }),
        new CognitoUserAttribute({ Name: "family_name", Value: lastName }),
        new CognitoUserAttribute({ Name: "nickname", Value: displayName }),
      ];

      userPool.signUp(email, password, attributes, null, (err, result) => {
        if (err) {
          console.error("Sign-up error:", err);
          if (err.code === "UsernameExistsException") {
          // Stay on the page and show an error
          setErrorMsg(
            "A user with this email address already exists. Please sign in or use a different email."
          );
        } else {
          setErrorMsg(
            err.message || "An error occurred during sign-up. Please try again."
          );
        }
      } else {
        console.log("Sign-up successful. Navigating to confirm-signup");
    navigate("/confirm-signup", {
      state: {
        email,
            userSub: result.userSub,
        firstName,
        lastName,
        displayName,
      },
    });
      }
    });
  };

  return (
    <Container maxW="md" mt={8}>
      {errorMsg && (
        <Box p={4} mb={4} bg="red.100" color="red.700" borderRadius="md">
          {errorMsg}
        </Box>
      )}

      <VStack
        as="form"
        spacing={4}
        align="stretch"
        onSubmit={handleSignUp}
      >
        <Field label="E-mail address">
          <Input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </Field>

        <HStack width="full" spacing={4}>
          <Field label="First Name">
            <Input
              type="text"
              name="firstName"
              value={firstName}
              onChange={handleChange}
            />
          </Field>
          <Field label="Last Name">
            <Input
              type="text"
              name="lastName"
              value={lastName}
              onChange={handleChange}
            />
          </Field>
        </HStack>

        <Field label="Display Name">
          <Input
            type="text"
            name="displayName"
            value={displayName}
            onChange={handleChange}
          />
        </Field>

        <Field label="Password">
          <PasswordInput
            name="password"
            value={password}
            onChange={handleChange}
          />
          {!passwordValid && password.length > 0 && (
            <Text fontSize="xs" color="red.500">
              Password must be at least 8 characters, include a number, and a letter.
            </Text>
          )}
        </Field>

        <Field label="Confirm password">
          <PasswordInput
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
          />
          {!passwordsMatch && confirmPassword.length > 0 && (
            <Text fontSize="xs" color="red.500">
              Please make sure your passwords match.
            </Text>
          )}
        </Field>

        <Button
          type="submit"
          colorPalette="blue"
          width="full"
          disabled={!allFormsFilled()}
        >
          Sign Up
        </Button>

        <Text textAlign="center" fontSize="sm">
          Already have an account?{" "}
          <Box as={RouterLink} to="/login" color="blue.500">
            Sign In
          </Box>
        </Text>
      </VStack>
    </Container>
  );
}
