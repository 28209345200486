import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTrigger,
} from "../ui/dialog";
import PropTypes from "prop-types";
import { React } from "react";
import { VscDebugRestart } from "react-icons/vsc";
import { useAnomalySimulationEnding } from "../../api/scenarios";
import { Button } from "../ui/button";

export const RestartSimulationDialog = ({ handleRestart }) => {
  const handleAnomalyEnding = useAnomalySimulationEnding();
  const handleRestartSimulation = () => {
    handleAnomalyEnding();
    handleRestart();
  };

  return (
    <DialogRoot isCentered role="alertdialog">
      <DialogBackdrop />
      <DialogTrigger>
        <Button colorPalette="red" width="100%">
          <VscDebugRestart />
          Restart
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader fontSize="lg" fontWeight="bold">
          Restart Simulation
        </DialogHeader>
        <DialogBody>
          Are you sure you want to restart the simulation? This will end the
          current session.
        </DialogBody>
        <DialogFooter>
          <DialogActionTrigger>
            <Button>Cancel</Button>
          </DialogActionTrigger>
          <Button colorPalette="red" onClick={handleRestartSimulation}>
            Restart
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

RestartSimulationDialog.propTypes = {
  handleRestart: PropTypes.func.isRequired,
};
