import { Toaster, toaster } from "../ui/toaster";
import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  Icon,
  IconButton,
  Table,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiCheckCircle, FiEdit } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { RiCloseFill, RiDeleteBin6Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { scenarioService } from "../../services/scenarioService";

const ScenarioReader = () => {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchScenarios();
  }, []);

  const fetchScenarios = async () => {
    try {
      const fetchedScenarios = await scenarioService.getScenarios();
      console.log("Fetched scenarios:", fetchedScenarios);
      setScenarios(fetchedScenarios);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching scenarios:", err);
      setError(`Failed to fetch scenarios: ${err.message}`);
      setLoading(false);
    }
  };

  const handleEdit = (scenarioName) => {
    // Use the scenario name as the identifier
    navigate(`/scenario-builder/${encodeURIComponent(scenarioName)}`);
  };

  const handleClone = async (scenarioName) => {
    try {
      const clonedScenario = await scenarioService.cloneScenario(scenarioName);
      setScenarios([...scenarios, clonedScenario.name]);
      toaster.create({
        title: "Scenario cloned",
        type: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toaster.create({
        title: "Failed to clone scenario",
        description: err.message,
        type: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRename = async (oldName, newName) => {
    try {
      await scenarioService.renameScenario(oldName, newName);
      setScenarios(scenarios.map((s) => (s === oldName ? newName : s)));
      toaster.create({
        title: "Scenario renamed",
        type: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toaster.create({
        title: "Failed to rename scenario",
        description: err.message,
        type: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (scenarioId) => {
    if (window.confirm("Are you sure you want to delete this scenario?")) {
      try {
        await scenarioService.deleteScenario(scenarioId);
        fetchScenarios();
        toaster.create({
          title: "Scenario deleted",
          type: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (err) {
        toaster.create({
          title: "Failed to delete scenario",
          description: err.message,
          type: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleCreate = () => {
    navigate("/scenario-builder");
  };

  if (loading) return <Box>Loading scenarios...</Box>;
  if (error) return <Box color="red.500">Error: {error}</Box>;

  return (
    <Box p={5}>
      <Toaster />
      <VStack spacing={5} align="stretch">
        <Heading as="h1" size="xl">
          Scenario Management
        </Heading>
        <Button
          leftIcon={
            <Icon>
              <IoMdAdd />
            </Icon>
          }
          colorScheme="blue"
          onClick={handleCreate}
        >
          Create New Scenario
        </Button>
        <Table variant="simple">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader>Name</Table.ColumnHeader>
              <Table.ColumnHeader>Actions</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {scenarios.map((scenario) => (
              <Table.Row key={scenario}>
                <Table.Cell>
                  <Editable
                    defaultValue={scenario}
                    onSubmit={(newName) => handleRename(scenario, newName)}
                  >
                    {(props) => (
                      <HStack>
                        <EditablePreview />
                        <EditableInput />
                        <IconButton
                          borderRight={2}
                          icon={
                            <Icon>
                              <FiCheckCircle />
                            </Icon>
                          }
                          {...props.submitButtonProps}
                        />
                        <IconButton
                          icon={
                            <Icon>
                              <RiCloseFill />
                            </Icon>
                          }
                          {...props.cancelButtonProps}
                        />
                      </HStack>
                    )}
                  </Editable>
                </Table.Cell>
                <Table.Cell>
                  <HStack spacing={2}>
                    <Button
                      leftIcon={
                        <Icon>
                          <FiEdit />
                        </Icon>
                      }
                      colorScheme="green"
                      size="sm"
                      onClick={() => handleEdit(scenario)}
                    >
                      Edit
                    </Button>
                    <Button
                      leftIcon={
                        <Icon>
                          <MdOutlineContentCopy />
                        </Icon>
                      }
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleClone(scenario)}
                    >
                      Clone
                    </Button>
                    <Button
                      leftIcon={
                        <Icon>
                          <RiDeleteBin6Fill />
                        </Icon>
                      }
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDelete(scenario)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </VStack>
    </Box>
  );
};

export default ScenarioReader;
