import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Card,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ProgressCircleRing,
  ProgressCircleRoot,
  ProgressCircleValueText,
} from "../ui/progress-circle";

export const PerformanceAndSummaryCard = ({ feedback }) => {
  const { summary, no_pass, overall_rating } = feedback;

  return (
    <Card.Root>
      <Card.Header bg="blue.50" p={4}>
        <Heading size="md">Performance Summary</Heading>
      </Card.Header>
      <Card.Body>
        <Grid templateColumns="1fr auto" gap={6}>
          <GridItem>
            <VStack align="start" spacing={2}>
              <Text>{summary || "No summary available"}</Text>
              {no_pass && (
                <Text fontSize="sm" color="red.600">
                  Some critical requirements were not met. Critical items are
                  essential points that must be addressed to pass the
                  evaluation.
                </Text>
              )}
            </VStack>
          </GridItem>

          <GridItem>
            <VStack spacing={4} minW="200px">
              <ProgressCircleRoot
                value={(overall_rating || 0) }
                size="xl"
                marginBottom={2}
                thickness="8px"
                colorPalette={
                  overall_rating >= 70
                    ? "green.400"
                    : overall_rating >= 50
                    ? "orange.400"
                    : "red.400"
                }
              >
                <ProgressCircleValueText />
                <ProgressCircleRing cap="round" />
              </ProgressCircleRoot>

              <Badge
                fontSize="md"
                colorPalette={!no_pass ? "green" : "red"}
                p={2}
                borderRadius="md"
              >
                {!no_pass ? "Pass" : "No Pass"}
              </Badge>
            </VStack>
          </GridItem>
        </Grid>
      </Card.Body>
    </Card.Root>
  );
};

PerformanceAndSummaryCard.propTypes = {
  feedback: PropTypes.shape({
    summary: PropTypes.string,
    no_pass: PropTypes.bool,
    overall_rating: PropTypes.number,
  }).isRequired,
};
