import {
  Button,
  Container,
  Fieldset,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom"; // Import Link for navigation
import { useAuth, userPool } from "../contexts/AuthContext";
import { Field } from "./ui/field";
import { Alert } from "./ui/alert";

export const Login = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: () => {
        setIsAuthenticated(true);
        navigate("/dashboard");
      },
      onFailure: (err) => {
        setError(err.message || "Login failed");
        setIsAuthenticated(false);
      },
    });
    setIsLoading(false);
  };

  return (
    <Container maxW="sm" mt={10}>
      <Text fontSize="2xl" mb={4}>
        Login
      </Text>
      {error && <Alert status="error" title={error} />}
      <Fieldset.Root>
        <Field mb={4} label="Email">
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Field>
        <Field mb={4} label="Password">
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Field>
        <Button
          type="submit"
          colorPalette="blue"
          width="full"
          loading={isLoading}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Fieldset.Root>
      <Text mt={4} textAlign="center">
        New to OsceCoach?{" "}
        <Link as={RouterLink} to="/signup" color="blue.500">
          Sign up
        </Link>
      </Text>
    </Container>
  );
};
