import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { useSimulationMode } from "../../contexts/SimulationModeContext";
import { GoBackToScenarioDialog } from "./GoBackToScenarioDialog";
import { RestartSimulationDialog } from "./RestartSimulationDialog";

export const SimulationInterfaceHeader = ({ handleRestart }) => {
  const { currentMode, timeRemaining } = useSimulationMode();

  return (
    <Flex justify="space-between" align="center" padding={8}>
      <Box>
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Medical Simulation {currentMode.id === "exam" ? "- Exam Mode" : ""}
        </Text>
      </Box>
      <HStack spacing={4}>
        {currentMode.id === "exam" && timeRemaining > 0 && (
          <Box
            bg={timeRemaining <= 60 ? "red.100" : "white"}
            p={4}
            borderRadius="md"
            boxShadow="sm"
          >
            <VStack spacing={1}>
              <Text fontWeight="bold">Time Remaining:</Text>
              <Text
                fontSize="xl"
                color={timeRemaining <= 60 ? "red.600" : "black"}
              >
                {Math.floor(timeRemaining / 60)}:
                {(timeRemaining % 60).toString().padStart(2, "0")}
              </Text>
            </VStack>
          </Box>
        )}

        <RestartSimulationDialog handleRestart={handleRestart} />
        <GoBackToScenarioDialog />
      </HStack>
    </Flex>
  );
};

SimulationInterfaceHeader.propTypes = {
  handleRestart: PropTypes.func.isRequired,
};
