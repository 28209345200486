import React from "react";

import { Box, Flex, IconButton, Spinner, Text } from "@chakra-ui/react";
import { PiSignOutBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Avatar } from "./ui/avatar";

export const NavBar = () => {
  const navigate = useNavigate();
  const { user, signOut, isLoading } = useAuth();

  if (isLoading) {
    // If still loading user data, show a spinner to avoid early component mount causing queries.
    return (
      <Box
        bgGradient="linear(to-r, blue.500, blue.700)"
        px={6}
        py={2}
        boxShadow="sm"
      >
        <Flex h={16} alignItems="center" justifyContent="center">
          <Spinner size="lg" colorPalette="white" borderWidth="4px" />
        </Flex>
      </Box>
    );
  }

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/login");
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  const displayName = user?.display_name || "User";

  return (
    <Box
      bg="blue.500"
      px={6}
      py={2}
      boxShadow="sm"
      position="relative"
      w={"100%"}
    >
      <Flex h={12} alignItems={"center"} justifyContent={"space-between"}>
        {/* Brand Name */}
        <Text
          fontSize="2xl"
          fontFamily="Poppins, sans-serif"
          fontWeight="bold"
          color="white"
          cursor="pointer"
          onClick={handleNavigateToDashboard}
          _hover={{ color: "blue.800", fontWeight: "900" }}
        >
          OsceCoach
        </Text>
        {/* User Info */}
        <Flex alignItems={"center"}>
          <Text color="gray.100" fontSize="lg" mr={4} fontWeight="medium">
            Welcome, {displayName}
          </Text>
          <IconButton
            onClick={() => navigate("/profile")}
            variant="ghost"
            size="sm"
            mr={2}
          >
            <Avatar size="sm" name={displayName} />
          </IconButton>
          <IconButton
            onClick={handleSignOut}
            variant="ghost"
            colorPalette="blue"
          >
            <PiSignOutBold />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  );
};
