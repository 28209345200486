import React from "react";
import PropTypes from "prop-types";
import { Table, Text } from "@chakra-ui/react";
import { Tag } from "../ui/tag";

export const ScenarioList = ({ filteredScenarios, onScenarioSelect }) => {
  return (
    <Table.ScrollArea
      borderWidth="1px"
      rounded="md"
      maxHeight="75vh"
      width="50vw"
    >
      <Table.Root size="lg" stickyHeader interactive>
        <Table.Header>
          <Table.Row bg="bg.subtle">
            <Table.ColumnHeader>Tittle</Table.ColumnHeader>
            <Table.ColumnHeader>Category</Table.ColumnHeader>
            <Table.ColumnHeader>Tags</Table.ColumnHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredScenarios.map((scenario) => {
            return (
              <Table.Row
                key={scenario.id}
                onClick={() => onScenarioSelect(scenario)}
              >
                <Table.Cell>
                  <Text truncate fontWeight="semibold">
                    {scenario.title}
                  </Text>
                </Table.Cell>
                <Table.Cell>{scenario.category}</Table.Cell>
                <Table.Cell>
                  {scenario.tags.map((tag, index) => (
                    <Tag key={index} colorPalette="blue" mr={1}>
                      {tag}
                    </Tag>
                  ))}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </Table.ScrollArea>
  );
};

ScenarioList.propTypes = {
  onScenarioSelect: PropTypes.func.isRequired,
  filteredScenarios: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
