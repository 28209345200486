import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Box, ChakraProvider, defaultSystem } from "@chakra-ui/react";
import { AuthProvider } from "./contexts/AuthContext";
import { AppRoutes } from "./Routes";
import { NavBar } from "./components/NavBar";
import PropTypes from "prop-types";

function App() {
  return (
    <ChakraProvider value={defaultSystem}>
      <AuthProvider>
        <Router>
          <Layout>
            <AppRoutes />
          </Layout>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

const Layout = ({ children }) => {
  const location = useLocation();

  // Routes without the NavBar
  const noNavBarRoutes = [
    "/login",
    "/confirm-signup",
    "/signup",
    "/simulation",
    "/exam-mode",
  ];

  return (
    <>
      {/* Conditionally render NavBar */}
      {!noNavBarRoutes.includes(location.pathname) && <NavBar />}
      <Box height="100vh">{children}</Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
