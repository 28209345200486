import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Box,
  Card,
  Flex,
  HStack,
  Icon,
  StatLabel,
  StatRoot,
  StatValueText,
  Text,
} from "@chakra-ui/react";
import { ProgressBar, ProgressRoot } from "../ui/progress";

export const TrainingProblemSetCard = ({
  problemSet,
  onSelect,
  isCardSelected,
}) => {
  const { icon, name, description, difficulty, problems, time } = problemSet;

  const getColorByDifficulty = () => {
    switch (difficulty) {
      case "Hard":
        return "red";
      case "Easy":
        return "green";
      case "Mixed":
        return "purple";
      default:
        return "gray";
    }
  };

  return (
    <Card.Root
      cursor="pointer"
      onClick={onSelect}
      bg={isCardSelected ? "blue.50" : "white"}
      borderColor={isCardSelected ? "blue.500" : "gray.200"}
      borderWidth={2}
      _hover={{ transform: "translateY(-2px)", shadow: "md" }}
      transition="all 0.3s"
    >
      <Card.Body justifyContent={"space-between"}>
        <Flex align="top" justify="space-between" mb={2}>
          <div>
            <Card.Title>{name}</Card.Title>
            <Text fontSize="sm" color="gray.600" mb={3}>
              {description}
            </Text>
          </div>
          <Box
            minW="50px"
            h="50px"
            backgroundColor={`${getColorByDifficulty()}.100`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="5px"
            ml={2}
          >
            <Icon size="md" color="gray.700">
              {icon}
            </Icon>
          </Box>
        </Flex>
        <div>
          <HStack>
            <StatRoot mb={3}>
              <StatLabel fontSize="xs" color="gray.500">
                Problems
              </StatLabel>
              <StatValueText fontSize="lg">{problems}</StatValueText>
            </StatRoot>
            <StatRoot mb={3}>
              <StatLabel fontSize="xs" colorP>
                Minutes
              </StatLabel>
              <StatValueText fontSize="lg">{time}</StatValueText>
            </StatRoot>
          </HStack>
          <Badge colorPalette={getColorByDifficulty()}>{difficulty}</Badge>
        </div>
      </Card.Body>
    </Card.Root>
  );
};

TrainingProblemSetCard.propTypes = {
  problemSet: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    difficulty: PropTypes.string.isRequired,
    problems: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  isCardSelected: PropTypes.bool.isRequired,
};
