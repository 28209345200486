import React from "react";
import { BsArrowRepeat, BsFillEyeFill, BsStarFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { FiCheckCircle, FiEdit } from "react-icons/fi";
import { IoIosWarning, IoMdLock } from "react-icons/io";
import { IoInformationCircle } from "react-icons/io5";
import { WiTime8 } from "react-icons/wi";

export const mockCategories = [
  {
    id: "pharmacology",
    name: "Pharmacology",
    totalProblems: 150,
    completedProblems: 45,
    icon: <FaStar />,
    description: "Drug mechanisms, interactions, and effects",
  },
  {
    id: "patient_counseling",
    name: "Patient Counseling",
    totalProblems: 200,
    completedProblems: 80,
    icon: <BsFillEyeFill />,
    description: "Communication and patient care skills",
  },
  {
    id: "clinical_cases",
    name: "Clinical Cases",
    totalProblems: 180,
    completedProblems: 20,
    icon: <IoInformationCircle />,
    description: "Real-world pharmacy scenarios",
  },
  {
    id: "drug_interactions",
    name: "Drug Interactions",
    totalProblems: 120,
    completedProblems: 60,
    icon: <IoIosWarning />,
    description: "Medication conflict analysis",
  },
  {
    id: "pharmacy_law",
    name: "Pharmacy Law",
    totalProblems: 100,
    completedProblems: 30,
    icon: <IoMdLock />,
    description: "Legal requirements and regulations",
  },
  {
    id: "pharmacy_practice",
    name: "Pharmacy Practice",
    totalProblems: 160,
    completedProblems: 90,
    icon: <FiCheckCircle />,
    description: "Professional pharmacy operations",
  },
];

export const mockProblemSets = [
  {
    id: "daily",
    name: "Daily Practice",
    problems: 10,
    time: 20,
    difficulty: "Mixed",
    description: "Fresh set of problems updated daily",
    icon: <BsArrowRepeat />,
  },
  {
    id: "quick",
    name: "Quick Review",
    problems: 5,
    time: 10,
    difficulty: "Easy",
    description: "Short practice session for quick learning",
    icon: <WiTime8 />,
  },
  {
    id: "comprehensive",
    name: "Comprehensive",
    problems: 20,
    time: 45,
    difficulty: "Hard",
    description: "In-depth coverage of multiple topics",
    icon: <BsStarFill />,
  },
  {
    id: "custom",
    name: "Custom Set",
    problems: 15,
    time: 30,
    difficulty: "Custom",
    description: "Personalized practice set",
    icon: <FiEdit />,
  },
];
