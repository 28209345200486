import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  HStack,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { Avatar } from "../ui/avatar";
import { Button } from "../ui/button";
import { BsChatLeftText, BsX } from "react-icons/bs";

export const ConversationPanel = ({ conversationLog, isOpen, onToggle }) => {
  if (!conversationLog?.length) {
    return null;
  }

  return (
    <>
      {!isOpen && (
        <Box 
        position="fixed"
          left="20px" 
          bottom="20px" 
        zIndex={100}
        >
          <Button
            variant="solid"
        onClick={onToggle}
        colorPalette="blue"
        size="lg"
        display="flex"
        alignItems="center"
        gap={2}
            boxShadow="lg"
      >
            <BsChatLeftText /> Show Transcript
      </Button>
        </Box>
      )}
      <Box
        position="fixed"
        left={0}
        top="64px"
        height="calc(100vh - 64px)"
        width="320px"
        transform={isOpen ? "translateX(0)" : "translateX(-320px)"}
        transition="transform 0.3s ease-in-out"
        bg="white"
        boxShadow="lg"
        zIndex={99}
      >
        <Card.Root h="100%">
          <Card.Header bg="blue.50" p={4}>
            <Flex justify="space-between" align="center">
            <HStack spacing={2}>
              <BsChatLeftText />
                <Text fontSize="lg" fontWeight="bold">Transcript</Text>
            </HStack>
              <Button
                variant="outline"
                size="sm"
                colorPalette="blue"
                onClick={onToggle}
                leftIcon={<BsX size={20} />}
              >
                Close
              </Button>
            </Flex>
          </Card.Header>
          <Card.Body p={4} overflowY="auto">
            <VStack spacing={4} align="stretch">
              {conversationLog.map((message, index) => (
                <Box 
                  key={index}
                  bg={message.speaker === "User" ? "gray.50" : "blue.50"}
                  p={4}
                  borderRadius="md"
                >
                  <HStack spacing={3} mb={2}>
                    <Avatar 
                      size="sm"
                      name={message.speaker === "User" ? "You" : message.speaker}
                      bg={message.speaker === "User" ? "gray.500" : "blue.500"}
                      color="white"
                    />
                    <Text fontWeight="bold">
                      {message.speaker === "User" ? "You" : message.speaker}
                    </Text>
                  </HStack>
                  <Text>{message.text}</Text>
                  {message.audio_link && (
                    <Box mt={2}>
                      <audio 
                        controls 
                        src={message.audio_link}
                        style={{ width: '100%' }}
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </VStack>
          </Card.Body>
        </Card.Root>
      </Box>
    </>
  );
};

ConversationPanel.propTypes = {
  conversationLog: PropTypes.arrayOf(
    PropTypes.shape({
      speaker: PropTypes.string.isRequired,
      role: PropTypes.string,
      text: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      audio_link: PropTypes.string,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};