import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from "../ui/dialog";
import { Box, Center, List, ListItem, Text, VStack } from "@chakra-ui/react";
import { ProgressBar, ProgressRoot } from "../ui/progress";
import { ProgressCircleRing, ProgressCircleRoot } from "../ui/progress-circle";
import { Button } from "../ui/button";
import { useSimulationMode } from "../../contexts/SimulationModeContext";

const EXAM_MODE_DIALOG_AUTO_START_TIME = 120;

export const WelcomeOverlay = ({
  isOpen,
  initialMessage,
  handleSimulationStart,
  hasStartedSimulation,
}) => {
  const { currentMode } = useSimulationMode();
  const [timeLeft, setTimeLeft] = useState(EXAM_MODE_DIALOG_AUTO_START_TIME);

  useEffect(() => {
    if (
      isOpen &&
      currentMode.id === "exam" &&
      initialMessage &&
      !hasStartedSimulation
    ) {
      if (timeLeft <= 0) return;

      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            handleSimulationStart();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isOpen, currentMode, hasStartedSimulation, initialMessage]);

  const LoadingSpinner = () => {
    return (
      <DialogBody>
        <Center margin={4}>
          <ProgressCircleRoot value={null} size="sm" mr={3} colorPalette="blue">
            <ProgressCircleRing cap="round" css={{ "--thickness": "3px" }} />
          </ProgressCircleRoot>
          Preparing your simulation...
        </Center>
      </DialogBody>
    );
  };

  return (
    <DialogRoot
      open={isOpen && !hasStartedSimulation}
      isCentered
      size="xl"
      padding={8}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Welcome to the Simulation</DialogTitle>
        </DialogHeader>
        {initialMessage ? (
          <>
            <DialogBody>
              <VStack spacing={4}>
                {currentMode.id === "exam" && (
                  <Box marginBottom={4} width="100%">
                    <Text fontSize="lg" color="blue.500">
                      Time until auto-start: {Math.floor(timeLeft / 60)}:
                      {(timeLeft % 60).toString().padStart(2, "0")}
                    </Text>
                    <ProgressRoot
                      colorPalette={timeLeft <= 10 ? "red" : "blue"}
                      mt={2}
                      size="sm"
                      value={
                        (timeLeft / EXAM_MODE_DIALOG_AUTO_START_TIME) * 100
                      }
                    >
                      <ProgressBar />
                    </ProgressRoot>
                  </Box>
                )}
                <Text>{initialMessage}</Text>
                {currentMode.id === "exam" && (
                  <Box bg="yellow.100" p={4} borderRadius="md" width="100%">
                    <Text fontWeight="bold">Exam Mode Rules:</Text>
                    <List.Root mt={2}>
                      <ListItem>
                        You have 7 minutes to complete this simulation
                      </ListItem>
                      <ListItem>
                        No hints or feedback will be provided during the exam
                      </ListItem>
                      <ListItem>
                        The simulation will automatically end when time expires
                      </ListItem>
                      <ListItem>
                        The exam will auto-start in 2 minutes if you don't start
                        it manually
                      </ListItem>
                    </List.Root>
                  </Box>
                )}
              </VStack>
            </DialogBody>
            <DialogFooter>
              <Button colorPalette="blue" onClick={handleSimulationStart}>
                {currentMode.id === "exam" ? "Start Now" : "Start Simulation"}
              </Button>
            </DialogFooter>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </DialogContent>
    </DialogRoot>
  );
};

WelcomeOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialMessage: PropTypes.string,
  handleSimulationStart: PropTypes.func.isRequired,
  hasStartedSimulation: PropTypes.bool.isRequired,
};
