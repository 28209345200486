import axios from "axios";

const API_URL = "/api/scenario_edit";

export const scenarioService = {
  getScenarios: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data.scenarios;
    } catch (error) {
      console.error("Error fetching scenarios:", error);
      throw error;
    }
  },

  getScenario: async (scenarioName) => {
    try {
      console.log("Scenario name", scenarioName);
      const response = await axios.get(
        `${API_URL}/${encodeURIComponent(scenarioName)}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching scenario ${scenarioName}:`, error);
      throw error;
    }
  },

  updateScenario: async (scenarioName, scenario) => {
    try {
      const response = await axios.put(
        `${API_URL}/${encodeURIComponent(scenarioName)}`,
        scenario
      );
      return response.data;
    } catch (error) {
      console.error(`Error updating scenario ${scenarioName}:`, error);
      throw error;
    }
  },

  cloneScenario: async (scenarioName) => {
    try {
      const response = await axios.post(
        `${API_URL}/clone/${encodeURIComponent(scenarioName)}`
      );
      return response.data;
    } catch (error) {
      console.error(`Error cloning scenario ${scenarioName}:`, error);
      throw error;
    }
  },

  renameScenario: async (oldName, newName) => {
    try {
      const response = await axios.put(`${API_URL}/rename`, {
        old_name: oldName,
        new_name: newName,
      });
      return response.data;
    } catch (error) {
      console.error(
        `Error renaming scenario from ${oldName} to ${newName}:`,
        error
      );
      throw error;
    }
  },

  deleteScenario: async (scenarioId) => {
    try {
      await axios.delete(`${API_URL}/${scenarioId}`);
    } catch (error) {
      console.error(`Error deleting scenario ${scenarioId}:`, error);
      throw error;
    }
  },
};
