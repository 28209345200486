import * as React from "react";
import { Badge, Box, Button, Flex, Grid, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  FaAmbulance,
  FaCalculator,
  FaClipboardList,
  FaPills,
  FaUserInjured,
  FaUserMd,
  FaLock,
} from "react-icons/fa";
import { IoMdLock } from "react-icons/io";

// Study plan categories
const studyPlans = [
  {
    icon: <FaPills />,
    title: "Patient Counseling",
    description: "Must-do scenarios for OSCE",
    count: "25 Questions",
    isPremium: true,
    path: "/study-plan/patient-counseling" // Added path for navigation
  },
  {
    icon: <FaCalculator />,
    title: "Pharmacy Calculations",
    description: "Essential calculations practice",
    count: "15 Questions",
    isPremium: true,
  },
  {
    icon: <FaUserMd />,
    title: "Communications",
    description: "Healthcare team interactions",
    count: "20 Questions",
    isPremium: true,
  },
  {
    icon: <FaAmbulance />,
    title: "Emergency Scenarios",
    description: "High-stakes situations",
    count: "10 Questions",
    isPremium: true,
  },
  {
    icon: <FaClipboardList />,
    title: "Medication Review",
    description: "Comprehensive medication analysis",
    count: "30 Questions",
    isPremium: true,
  },
  {
    icon: <FaUserInjured />,
    title: "Patient Assessment",
    description: "Clinical evaluation practice",
    count: "25 Questions",
    isPremium: true,
  },
];

export const StudyPlanContainer = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Study Plan
        </Text>
        <Button size="sm" colorPalette="blue" variant="ghost">
          See all
        </Button>
      </Flex>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {studyPlans.map(
          ({ icon, title, description, count, isPremium, path }, index) => (
            <Flex
              key={index}
              p={4}
              borderRadius="lg"
              border="1px"
              borderColor="gray.200"
              bg="white"
              _hover={{ shadow: "md", transform: "translateY(-2px)" }}
              transition="all 0.2s"
              cursor="pointer"
              onClick={() => handleClick(path)}
            >
              <Box
                p={2}
                borderRadius="md"
                bg="gray.100"
                mr={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="48px"
                height="48px"
              >
                <Icon boxSize={6} color="gray.600">
                  {icon}
                </Icon>
              </Box>
              <Box flex="1">
                <Flex justify="space-between" align="center">
                  <Text fontWeight="bold" fontSize="md">
                    {title}
                  </Text>
                  {isPremium && (
                    <Badge colorPalette="purple">
                      <Icon color="purple.500" boxSize={3}>
                        <IoMdLock />
                      </Icon>
                      Premium
                    </Badge>
                  )}
                </Flex>
                <Flex justify="space-between" align="center">
                  <Text color="gray.600" fontSize="sm" mt={1}>
                    {description}
                  </Text>
                  <Text color="gray.500" fontSize="sm">
                    {count}
                  </Text>
                </Flex>
              </Box>
            </Flex>
          )
        )}
      </Grid>
    </>
  );
};

export default StudyPlanContainer;